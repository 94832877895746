import bank99 from "src/assets/banks/bank.bank99.svg";
import austria from "src/assets/banks/bank.austria.svg";
import bawag from "src/assets/banks/bank.bawag-colored.png";
import erste from "src/assets/banks/bank.erste.svg";
import hypoNoe from "src/assets/banks/bank.hypoNoe.svg";
import oberbank from "src/assets/banks/bank.oberbank.svg";
import raiffeisen from "src/assets/banks/bank.raiffeisenBsk.svg";
import start from "src/assets/banks/bank.start-colored.png";
import volksbank from "src/assets/banks/bank.volksbank-colored.png";
import wuestenrot from "src/assets/banks/bank.wuestenrot-colored.jpg";
import {
    BorrowerHouseBank,
    ExportMode,
    BankIdentifierProtocol,
    DocumentPersonalTypeDimension,
    DocumentObjectTypeDimension,
} from "@miraclapp/mortgaging-shared";

export const MAX_NUMBER_OF_OPTIONS = 4;

export enum BankRequirement {
    MANDATORY_SALARY_ACCOUNT = "mandatorySalaryAccount",
    MANDATORY_INSURANCE = "mandatoryInsurance",
    HOUSEHOLD_INSURANCE_FREELY_SELECTABLE = "householdInsuranceFreelySelectable",
    BUILDING_SAVINGS_CONTRACT = "buildingSavingsContract",
    MORTGAGE = "mortgage",
    SALARY_PLEDGE = "salaryPledge",
    SIGNATURE_IN_VIENNA = "signatureInVienna",
}

type BankData = {
    key: BankIdentifierProtocol;
    name: string;
    src?: string;
    defaultExportMode: ExportMode;
    bankEnumeration: number;
    requirements: BankRequirement[];
};

export const BANK_DATA: Record<BankIdentifierProtocol, BankData> = {
    [BankIdentifierProtocol.bankAustria]: {
        key: BankIdentifierProtocol.bankAustria,
        name: BorrowerHouseBank.UNICREDIT,
        src: austria,
        defaultExportMode: "single",
        bankEnumeration: 1,
        requirements: [
            BankRequirement.HOUSEHOLD_INSURANCE_FREELY_SELECTABLE,
            BankRequirement.MORTGAGE,
            BankRequirement.SALARY_PLEDGE,
            BankRequirement.MANDATORY_SALARY_ACCOUNT,
        ],
    },
    [BankIdentifierProtocol.bawag]: {
        key: BankIdentifierProtocol.bawag,
        name: BorrowerHouseBank.BAWAG,
        src: bawag,
        defaultExportMode: "group-split-borrowers",
        bankEnumeration: 2,
        requirements: [
            BankRequirement.HOUSEHOLD_INSURANCE_FREELY_SELECTABLE,
            BankRequirement.MORTGAGE,
            BankRequirement.SALARY_PLEDGE,
        ],
    },
    [BankIdentifierProtocol.erste]: {
        key: BankIdentifierProtocol.erste,
        name: BorrowerHouseBank.ERSTE,
        src: erste,
        defaultExportMode: "single",
        bankEnumeration: 3,
        requirements: [
            BankRequirement.MANDATORY_SALARY_ACCOUNT,
            BankRequirement.HOUSEHOLD_INSURANCE_FREELY_SELECTABLE,
            BankRequirement.MORTGAGE,
            BankRequirement.SALARY_PLEDGE,
        ],
    },
    [BankIdentifierProtocol.hypoNoeW]: {
        key: BankIdentifierProtocol.hypoNoeW,
        name: BorrowerHouseBank.HYPO,
        src: hypoNoe,
        defaultExportMode: "single",
        bankEnumeration: 4,
        requirements: [
            BankRequirement.HOUSEHOLD_INSURANCE_FREELY_SELECTABLE,
            BankRequirement.MORTGAGE,
            BankRequirement.SALARY_PLEDGE,
        ],
    },
    [BankIdentifierProtocol.bank99]: {
        key: BankIdentifierProtocol.bank99,
        name: BorrowerHouseBank.BANK_99,
        src: bank99,
        defaultExportMode: "single",
        bankEnumeration: 5,
        requirements: [
            BankRequirement.HOUSEHOLD_INSURANCE_FREELY_SELECTABLE,
            BankRequirement.MORTGAGE,
            BankRequirement.SALARY_PLEDGE,
        ],
    },
    [BankIdentifierProtocol.oberbank]: {
        key: BankIdentifierProtocol.oberbank,
        name: BorrowerHouseBank.OBERBANK,
        src: oberbank,
        defaultExportMode: "single",
        bankEnumeration: 6,
        requirements: [
            BankRequirement.HOUSEHOLD_INSURANCE_FREELY_SELECTABLE,
            BankRequirement.MORTGAGE,
            BankRequirement.SALARY_PLEDGE,
            BankRequirement.SIGNATURE_IN_VIENNA,
        ],
    },
    [BankIdentifierProtocol.raiBau]: {
        key: BankIdentifierProtocol.raiBau,
        name: BorrowerHouseBank.RAI_BAU,
        src: raiffeisen,
        defaultExportMode: "single",
        bankEnumeration: 7,
        requirements: [
            BankRequirement.HOUSEHOLD_INSURANCE_FREELY_SELECTABLE,
            BankRequirement.MORTGAGE,
            BankRequirement.SALARY_PLEDGE,
            BankRequirement.MANDATORY_INSURANCE,
            BankRequirement.MANDATORY_SALARY_ACCOUNT,
            BankRequirement.BUILDING_SAVINGS_CONTRACT,
        ],
    },
    [BankIdentifierProtocol.rlbNoeW]: {
        key: BankIdentifierProtocol.rlbNoeW,
        name: BorrowerHouseBank.RAI_NW,
        defaultExportMode: "single",
        bankEnumeration: 8,
        requirements: [
            BankRequirement.HOUSEHOLD_INSURANCE_FREELY_SELECTABLE,
            BankRequirement.MORTGAGE,
            BankRequirement.SALARY_PLEDGE,
            BankRequirement.MANDATORY_INSURANCE,
            BankRequirement.MANDATORY_SALARY_ACCOUNT,
        ],
    },
    [BankIdentifierProtocol.sBau]: {
        key: BankIdentifierProtocol.sBau,
        name: BorrowerHouseBank.BAUSPARKASSE_OST,
        defaultExportMode: "single",
        bankEnumeration: 9,
        requirements: [
            BankRequirement.HOUSEHOLD_INSURANCE_FREELY_SELECTABLE,
            BankRequirement.MORTGAGE,
            BankRequirement.SALARY_PLEDGE,
            BankRequirement.BUILDING_SAVINGS_CONTRACT,
        ],
    },
    [BankIdentifierProtocol.startBau]: {
        key: BankIdentifierProtocol.startBau,
        name: BorrowerHouseBank.START,
        src: start,
        defaultExportMode: "group-split-borrowers",
        bankEnumeration: 10,
        requirements: [
            BankRequirement.HOUSEHOLD_INSURANCE_FREELY_SELECTABLE,
            BankRequirement.MORTGAGE,
            BankRequirement.SALARY_PLEDGE,
            BankRequirement.BUILDING_SAVINGS_CONTRACT,
        ],
    },
    [BankIdentifierProtocol.volksbankNoe]: {
        key: BankIdentifierProtocol.volksbankNoe,
        name: BorrowerHouseBank.VOLKSBANK,
        src: volksbank,
        defaultExportMode: "single",
        bankEnumeration: 11,
        requirements: [
            BankRequirement.HOUSEHOLD_INSURANCE_FREELY_SELECTABLE,
            BankRequirement.MORTGAGE,
            BankRequirement.SALARY_PLEDGE,
        ],
    },
    [BankIdentifierProtocol.wuestenrot]: {
        key: BankIdentifierProtocol.wuestenrot,
        name: BorrowerHouseBank.BAUSPARKASSE_WUSTENROT,
        src: wuestenrot,
        defaultExportMode: "single",
        bankEnumeration: 12,
        requirements: [
            BankRequirement.HOUSEHOLD_INSURANCE_FREELY_SELECTABLE,
            BankRequirement.MORTGAGE,
            BankRequirement.SALARY_PLEDGE,
            BankRequirement.MANDATORY_SALARY_ACCOUNT,
        ],
    },
    [BankIdentifierProtocol.volksbankW]: {
        key: BankIdentifierProtocol.volksbankW,
        name: BorrowerHouseBank.VOLKSBANK_W,
        defaultExportMode: "single",
        bankEnumeration: 13,
        requirements: [
            BankRequirement.HOUSEHOLD_INSURANCE_FREELY_SELECTABLE,
            BankRequirement.MORTGAGE,
            BankRequirement.SALARY_PLEDGE,
        ],
    },
    [BankIdentifierProtocol.vrNo]: {
        key: BankIdentifierProtocol.vrNo,
        name: BorrowerHouseBank.VR,
        defaultExportMode: "single",
        bankEnumeration: 14,
        requirements: [
            BankRequirement.HOUSEHOLD_INSURANCE_FREELY_SELECTABLE,
            BankRequirement.MORTGAGE,
            BankRequirement.SALARY_PLEDGE,
        ],
    },
    // This bank exists for unversal engine api requests
    [BankIdentifierProtocol.universal]: {
        key: BankIdentifierProtocol.universal,
        name: undefined,
        src: undefined,
        defaultExportMode: "single",
        bankEnumeration: -1,
        requirements: [],
    },
};

export const BANKS_WITH_FINLINE_SUBMISSION: BankIdentifierProtocol[] = [BankIdentifierProtocol.bankAustria];

const IGNORED_BANKS: BankIdentifierProtocol[] = [BankIdentifierProtocol.vrNo, BankIdentifierProtocol.universal];

export const DEFAULT_SUPPORTED_BANKS: BankIdentifierProtocol[] = Object.values(BankIdentifierProtocol).filter(
    (bank) => !IGNORED_BANKS.includes(bank),
);

export const EXCLUDED_DOCUMENT_TYPES_PER_BANK: Partial<
    Record<DocumentPersonalTypeDimension | DocumentObjectTypeDimension, BankIdentifierProtocol[]>
> = {
    kontoumsätze: [BankIdentifierProtocol.bankAustria, BankIdentifierProtocol.hypoNoeW],
    nachweisFamilienbeihilfe: [BankIdentifierProtocol.bankAustria, BankIdentifierProtocol.bank99],
    kreditvertrag: [BankIdentifierProtocol.bank99],
};
