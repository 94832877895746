export enum DealStage {
    /* Deal-stage defined for (existing) customer's life-cycle timeline */
    SURVEY_COMPLETED = "appointmentscheduled",

    /* Angebot gesendet */
    CUSTOMER_REACHED = "61788702",

    CUSTOMER_QUALIFIED = "61788703",

    /* Beratunsgespräch organisiert */
    CONSULTATION_SCHEDULED = "qualifiedtobuy",

    /* Beratungsgespräch stattgefunden */
    CONSULTATION_COMPLETED = "presentationscheduled",

    /* Vermittlungsauftrag gesendet */
    CONTRACT_ASSIGNMENT_SENT = "13320517",

    /* Vermittlungsauftrag unterschrieben */
    CONTRACT_ASSIGNMENT_SIGNED = "61788705",

    /* Unterlagen erhalten */
    DOCUMENTS_RECEIVED = "61742228",

    /* Unterlagen vollständig */
    DOCUMENTS_COMPLETE = "12540427",

    /* Bankunterlagen gesendet */
    BANK_DOCUMENTS_SENT = "61742229",

    /* Bankunterlagen unterschrieben */
    BANK_DOCUMENTS_SIGNED = "61797784",

    /* Eingereicht */
    SUBMITTED = "1224357",

    /* Bankangebot erhalten */
    BANK_OFFER_RECEIVED = "12540428",

    /* Bankangebot angenommen */
    BANK_OFFER_ACCEPTED = "1218232",

    /* Bankangebot bewilligt */
    BANK_OFFER_APPROVED = "61797785",

    /* Kaufvertragsentwurf an Bank gesendet */
    PURCHASE_CONTRACT_DRAFT_SENT_TO_BANK = "61797786",

    /* Kreditvertrag erstellt */
    CREDIT_CONTRACT_CREATED = "61797787",

    /* Kreditvertrag besprochen */
    CREDIT_CONTRACT_DISCUSSED = "contractsent",

    /* Kreditvertrag unterschrieben */
    CREDIT_CONTRACT_SIGNED = "17167366",

    FOLLOW_UP_USER_LONG_TERM = "17167367",

    /* Vermittlungsauftrag unterschrieben */
    SIGNED_CONTRACT_ASSIGNMENT = "61788705",

    /* ------------------------------------------------------------------------*/

    /* User not interested or archived states */
    USER_ARCHIVED = "198668",

    /* User is "valid" in a sense of that we might reach out to him,
    we still did not (not to confuse with "invalid" number not reached) */
    NEVER_REACHED = "12540456",

    CONSUMER_CREDIT = "12556854",
}

export const DEAL_STAGE_VALUES = Object.values(DealStage);
