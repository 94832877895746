import { sentryService } from "src/service/sentry";
import { TranslationValue } from "../../context/LocaleContext";
import { get } from "./object";

export const makeTranslator =
    (translations: TranslationValue = {}) =>
    (key: string, variables: { [key: string]: string | number } = {}, fallback?: string) => {
        try {
            // Left for backwards compatibility in case we missed some case where old api with
            // fallback as 2nd argument was being used
            if (typeof variables === "string") {
                console.warn(`translator - old api used for key: ${key}`);
                return (get(translations, key) ?? variables ?? key) as string;
            }

            let translation = get(translations, key) as string;

            if (translation === "") {
                console.warn(`translator - empty translation for key: ${key}`);
                return translation;
            }

            if (!translation) {
                return fallback ?? key;
            }

            for (const [vKey, vValue] of Object.entries(variables)) {
                if (!translation.includes(`{{${vKey}}}`)) {
                    console.warn(`translator - invalid variable: ${vKey} for key: ${key}`);
                    continue;
                }

                translation = translation.replace(`{{${vKey}}}`, `${vValue}`);
            }

            return translation;
        } catch (err) {
            console.error(`An error occured while translating`, err);
            sentryService.report(`An error occured while translating key: ${key}`);
        }

        return "";
    };
