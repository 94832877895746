import {
    AustriaState,
    BorrowerAdditionalIncomeType,
    BorrowerEmploymentIndependenceType,
    BorrowerEmploymentType,
    BorrowerHouseBank,
    BorrowerIncomeCurrencyValues,
    BorrowerMaritalStatus,
    BorrowerResidenceType,
    BorrowerReferralType,
    BorrowerRentalIncomeType,
    BorrowerSalutationType,
    BorrowerWayOfLivingType,
    Country,
    DealAdditionalLiabilityFixedOrVariable,
    RelatedObjectType,
    DealAdditionalLiabilityType,
    DealAdditionalWealthType,
    DealNextStep,
    DealPropertyAtticType,
    DealPropertyBasementType,
    DealPropertyBathroomFurnitureType,
    DealPropertyElementType,
    DealPropertyEquipmentFeatureType,
    DealPropertyExteriorSurfaceType,
    DealPropertyFinancingType,
    DealPropertyHeatingSystemType,
    DealPropertyObjectPurpose,
    DealPropertyOrientationType,
    DealPropertyRealEstateOrientationType,
    DealPropertyType,
    DealPropertyWindowType,
    JaNeinValue,
    MortgageExtrapaymentWish,
    TrueFalseValue,
    VariableOriginReference,
    BorrowerEducationType,
    DealPropertyBasementRoughSizeType,
    BorrowerEnumerator,
    DealPropertyRiskType,
    DealPropertyFloorType,
    DealPropertyStatusType,
    BorrowerEmploymentDuration,
    DealPropertyLandDevelopmentPlannedType,
    DealAlreadyAskedBanks,
    NumberOfMonthsEmployed,
    ClosedLostReasonValues,
    ClosedLostReasonSingleValues,
    SurveyCustomerIntentType,
    ClosedLostReasonSingleFormOptionValues,
    ConsultationFinancingChoice,
    BorrowerMainResidenceType,
    BorrowerType,
    BorrowerRoughEmployedSince,
    BorrowerMaternityLeaveModel,
    ExistingPropertyEnumerator,
    DealPropertyUse,
    DealAdditionalLiabilityWhatWillHappen,
    BorrowerAlimony,
    DealPropertyConstructionType,
    PersonType,
    PersonReference,
    DealPropertyAdditionalHeatingSystemType,
    CustomerQualificationFinancingProgress,
    CustomerQualificationPurchaseProgress,
} from "../models";
import { extendWithNoOptionsValue } from "./util";

export const PROPERTY_TYPE_VALUES_WITH_LAND_AREA: DealPropertyType[] = [
    DealPropertyType.SINGLE_FAMILY_HOUSE,
    DealPropertyType.DOUBLE_HOUSE,
    DealPropertyType.TOWN_HOUSE,
    DealPropertyType.LAND_PROPERTY_TYPE,
];

export const PROPERTY_TYPE_VALUES: DealPropertyType[] = [
    ...PROPERTY_TYPE_VALUES_WITH_LAND_AREA,
    DealPropertyType.APARTMENT_PROPERTY_TYPE,
    DealPropertyType.COMMERCIAL_PROPERTY,
];

export const PROPERTY_USE_VALUES: DealPropertyUse[] = Object.values(DealPropertyUse);

export const PURCHASE_INTENT_TYPES: DealPropertyFinancingType[] = [
    DealPropertyFinancingType.PURCHASE,
    DealPropertyFinancingType.COOPERATIVE_APARTMENT,
    DealPropertyFinancingType.COOPERATIVE_CONTRIBUTION,
    DealPropertyFinancingType.INTERIM_FINANCING,
    DealPropertyFinancingType.RETIREMENT_APARTMENT,
];

export const LOW_CUSTOMER_INTENT_TYPES: SurveyCustomerIntentType[] = [
    SurveyCustomerIntentType.LOW,
    SurveyCustomerIntentType.FORECAST_LOW,
];

export const HIGH_CUSTOMER_INTENT_TYPES: SurveyCustomerIntentType[] = [
    SurveyCustomerIntentType.HIGH,
    SurveyCustomerIntentType.FORECAST_HIGH,
];

export const PROPERTY_FINANCING_TYPE_VALUES = Object.values(DealPropertyFinancingType);

export const PROPERTY_INTERIM_FINANCING_TYPE_VALUES: DealPropertyFinancingType[] = [
    DealPropertyFinancingType.PURCHASE,
    DealPropertyFinancingType.CONSTRUCTION,
];

export const PROPERTY_OBJECT_PURPOSE_VALUES = Object.values(DealPropertyObjectPurpose);

export const BORROWER_ENUMERATORS: BorrowerEnumerator[] = ["Borrower1", "Borrower2", "Borrower3", "Borrower4"];

export const BORROWER_ENUMERATOR_TO_INDEX_MAP: Record<BorrowerEnumerator, number> = {
    Borrower1: 0,
    Borrower2: 1,
    Borrower3: 2,
    Borrower4: 3,
};

export const BORROWER_INDEX_TO_ENUMERATOR_MAP: Record<number, BorrowerEnumerator> = {
    0: "Borrower1",
    1: "Borrower2",
    2: "Borrower3",
    3: "Borrower4",
};

export const PERSON_TYPE_VALUES = Object.values(PersonType);

export const PROPERTY_EXTERIOR_SURFACES = Object.values(DealPropertyExteriorSurfaceType);

export const PROPERTY_BASEMENT_VALUES = Object.values(DealPropertyBasementType);

export const PROPERTY_WINDOW_TYPES = Object.values(DealPropertyWindowType);

export const MARITAL_STATUS_VALUES = Object.values(BorrowerMaritalStatus);

export const EMPLOYMENT_TYPE_VALUES = Object.values(BorrowerEmploymentType);

export const BORROWER_ROUGH_EMPLOYED_SINCE_VALUES = Object.values(BorrowerRoughEmployedSince);

export const BORROWER_ALIMONY_VALUES = Object.values(BorrowerAlimony);

export const OLD_SELF_EMPLOYED_TYPE_VALUE = "Selbständig";

export const EMPLOYMENT_TYPE_OF_INDEPENDENCE_VALUES = Object.values(BorrowerEmploymentIndependenceType);

export const VARIABLE_INTEREST_RATE_REFERENCES = Object.values(VariableOriginReference);

export const SALUTATION_TYPE_VALUES = Object.values(BorrowerSalutationType);

export const WAY_OF_LIVING_VALUES = Object.values(BorrowerWayOfLivingType);

export const PROPERTY_ATTIC_VALUES = Object.values(DealPropertyAtticType);

export const PROPERTY_ORIENTATION_VALUES = Object.values(DealPropertyOrientationType);

export const BORROWER_RENTAL_INCOME_TYPE_VALUES = Object.values(BorrowerRentalIncomeType);

export const PROPERTY_REAL_ESTATE_ORIENTATION_VALUES = Object.values(DealPropertyRealEstateOrientationType);

export const PROPERTY_BATHROOM_FURNITURE_VALUES = Object.values(DealPropertyBathroomFurnitureType);

export const PROPERTY_HEATING_SYSTEM_VALUES = Object.values(DealPropertyHeatingSystemType);

export const PROPERTY_ADDITIONAL_HEATING_SYSTEMS_VALUES = extendWithNoOptionsValue(
    Object.values(DealPropertyAdditionalHeatingSystemType),
);

export const PROPERTY_EQUIPMENT_FEATURES_VALUES = extendWithNoOptionsValue(
    Object.values(DealPropertyEquipmentFeatureType),
);

export const OTHER_AVAILABLE_CITIZENSHIP_VALUES = [JaNeinValue.JA, JaNeinValue.NEIN];

export const SUPPORTED_COUNTRY_VALUES = Object.values(Country);

export const AUSTRIA_STATE_VALUES = Object.values(AustriaState);

export const NUMBER_OF_MONTHS_EMPLOYED_VALUES = [
    NumberOfMonthsEmployed.THREE,
    NumberOfMonthsEmployed.SIX,
    NumberOfMonthsEmployed.NINE,
    NumberOfMonthsEmployed.TWELVE,
];

export const JA_NEIN_VALUES: [JaNeinValue, JaNeinValue] = [JaNeinValue.JA, JaNeinValue.NEIN];
export const TRUE_FALSE_VALUES: [TrueFalseValue, TrueFalseValue] = [TrueFalseValue.TRUE, TrueFalseValue.FALSE];

export const ALREADY_ASKED_BANKS_VALUES = Object.values(DealAlreadyAskedBanks);

export const NAMED_REFERRAL_VALUES_WITHOUT_OTHER: BorrowerReferralType[] = [
    BorrowerReferralType.FORMER_CUSTOMER,
    BorrowerReferralType.MIRACL_WORKER,
    BorrowerReferralType.COOPERATION_PARTNER,
];

export const NAMED_REFERRAL_VALUES: BorrowerReferralType[] = [
    ...NAMED_REFERRAL_VALUES_WITHOUT_OTHER,
    BorrowerReferralType.OTHER,
];

export const REFERRAL_VALUES: BorrowerReferralType[] = [
    ...NAMED_REFERRAL_VALUES_WITHOUT_OTHER,
    BorrowerReferralType.GOOGLE,
    BorrowerReferralType.YOUTUBE,
    BorrowerReferralType.FACEBOOK,
    BorrowerReferralType.INSTAGRAM,
    BorrowerReferralType.TWITTER,
    BorrowerReferralType.NEWSPAPER,
    BorrowerReferralType.RADIO,
    BorrowerReferralType.TV,
    BorrowerReferralType.WILLHABEN,
    BorrowerReferralType.IMMOSCOUT,
    BorrowerReferralType.OTHER,
];

export const BORROWER_TYPE_VALUES = Object.values(BorrowerType);

export const PERSON_REFERENCE_VALUES = Object.values(PersonReference);

export const PROPERTY_ELEMENTS_VALUES = Object.values(DealPropertyElementType);

export const BORROWER_INCOME_CURRENCY_OPTIONS = Object.values(BorrowerIncomeCurrencyValues);

export const HOUSE_BANK_OPTIONS = Object.values(BorrowerHouseBank);

export const EXTRA_PAYMENT_WISH_VALUES = Object.values(MortgageExtrapaymentWish);

export const DEAL_ADDITIONAL_LIABILITY_TYPE_VALUES = Object.values(DealAdditionalLiabilityType);

export const DEAL_OBJECT_ADDITIONAL_LIABILITY_TYPE_VALUES: DealAdditionalLiabilityType[] = [
    DealAdditionalLiabilityType.MORTGAGE,
    DealAdditionalLiabilityType.SUBSIDIES,
];

export const DEAL_BORROWER_ADDITIONAL_LIABILITY_TYPE_VALUES: DealAdditionalLiabilityType[] = [
    DealAdditionalLiabilityType.CONSUMER_LOAN,
    DealAdditionalLiabilityType.CHART_OF_ACCOUNTS,
    DealAdditionalLiabilityType.LEASING,
    DealAdditionalLiabilityType.ALIMONY,
    DealAdditionalLiabilityType.ZERO_PERCENT_FINANCING,
    DealAdditionalLiabilityType.BUILDING_SAVINGS_CONTRACTS,
    DealAdditionalLiabilityType.GUARANTEES,
];

export const DEAL_ADDITIONAL_LIABILITY_CREDIT_OWNER_VALUES: BorrowerType[] = [
    BorrowerType.KN1,
    BorrowerType.KN2,
    BorrowerType.KN3,
    BorrowerType.KN4,
];

export const DEAL_ADDITIONAL_LIABILITY_WHAT_WILL_HAPPEN_VALUES: DealAdditionalLiabilityWhatWillHappen[] = Object.values(
    DealAdditionalLiabilityWhatWillHappen,
);

export const DEAL_RELATED_OBJECT_VALUES: RelatedObjectType[] = [
    RelatedObjectType.FINANCING_OBJECT,
    RelatedObjectType.EXISTING_OBJECT_1,
    RelatedObjectType.EXISTING_OBJECT_2,
    RelatedObjectType.EXISTING_OBJECT_3,
    RelatedObjectType.EXISTING_OBJECT_4,
];

export const PROPERTY_ENUMERATOR_TO_RELATED_OBJECT_VALUE_MAP: Record<
    "property" | ExistingPropertyEnumerator,
    RelatedObjectType
> = {
    property: RelatedObjectType.FINANCING_OBJECT,
    existingProperty1: RelatedObjectType.EXISTING_OBJECT_1,
    existingProperty2: RelatedObjectType.EXISTING_OBJECT_2,
    existingProperty3: RelatedObjectType.EXISTING_OBJECT_3,
    existingProperty4: RelatedObjectType.EXISTING_OBJECT_4,
};

export const DEAL_ADDITIONAL_LIABILITY_FIXED_OR_VARIABLE_VALUES: DealAdditionalLiabilityFixedOrVariable[] = [
    DealAdditionalLiabilityFixedOrVariable.FIXED,
    DealAdditionalLiabilityFixedOrVariable.VARIABLE,
    DealAdditionalLiabilityFixedOrVariable.BULLET,
];

export const DEAL_ADDITIONAL_INCOME_TYPE_VALUES = Object.values(BorrowerAdditionalIncomeType);

export const DEAL_NEXT_STEP_VALUES = Object.values(DealNextStep);

export const DEAL_ADDITIONAL_WEALTH_TYPE_VALUES = Object.values(DealAdditionalWealthType);

export const RESIDENCE_TYPE_VALUES = Object.values(BorrowerResidenceType);

export const BORROWER_EDUCATION_TYPE_VALUES = Object.values(BorrowerEducationType);

export const PROPERTY_BASEMENT_ROUGH_SIZE_TYPE_VALUES = Object.values(DealPropertyBasementRoughSizeType);

export const BORROWER_ENUMERATOR_CREDIT_OWNER_MAP: Record<BorrowerEnumerator, BorrowerType> = {
    Borrower1: BorrowerType.KN1,
    Borrower2: BorrowerType.KN2,
    Borrower3: BorrowerType.KN3,
    Borrower4: BorrowerType.KN4,
};

export const PERSON_INDEX_TO_REFERENCE_MAP: Record<number, PersonReference> = {
    0: PersonReference.PERSON_1,
    1: PersonReference.PERSON_2,
    2: PersonReference.PERSON_3,
    3: PersonReference.PERSON_4,
};

export const BORROWER_TYPE_TO_BORROWER_ENUMERATOR_MAP: Record<BorrowerType, BorrowerEnumerator> = {
    [BorrowerType.KN1]: "Borrower1",
    [BorrowerType.KN2]: "Borrower2",
    [BorrowerType.KN3]: "Borrower3",
    [BorrowerType.KN4]: "Borrower4",
};

export const PROPERTY_RISK_TYPE_VALUES = extendWithNoOptionsValue(Object.values(DealPropertyRiskType));

export const PROPERTY_FLOOR_TYPE_VALUES = Object.values(DealPropertyFloorType);

export const PROPERTY_STATUS_TYPE_VALUES = Object.values(DealPropertyStatusType);

export const BORROWER_EMPLOYMENT_DURATION_VALUES = Object.values(BorrowerEmploymentDuration);

export const BORROWER_MATERNITY_LEAVE_MODEL_VALUES = Object.values(BorrowerMaternityLeaveModel);

export const PROPERTY_LAND_DEVELOPMENT_PLANNED_VALUES = Object.values(DealPropertyLandDevelopmentPlannedType);

export const PROPERTY_CONSTRUCTION_TYPE_OPTIONS = Object.values(DealPropertyConstructionType);

export const CLOSED_LOST_REASON_VALUES = Object.values(ClosedLostReasonValues);

export const CLOSED_LOST_REASON_SINGLE_FORM_OPTIONS_VALUES = Object.values(ClosedLostReasonSingleFormOptionValues);

export const CLOSED_LOST_REASON_SINGLE_VALUES = Object.values(ClosedLostReasonSingleValues);

export const CUSTOMER_QUALIFICATION_FINANCING_PROGRESS_VALUES = Object.values(CustomerQualificationFinancingProgress);

export const CUSTOMER_QUALIFICATION_PURCHASE_PROGRESS_VALUES = Object.values(CustomerQualificationPurchaseProgress);

export const NEXT_STEPS_TO_CLOSED_LOST_REASON_SINGLE_MAP: Record<
    DealNextStep,
    ClosedLostReasonSingleValues | undefined
> = {
    [DealNextStep.WAITING_FOR_OFFER]: ClosedLostReasonSingleValues.NO_ANSWER_WAITING_FOR_OFFER,
    [DealNextStep.DATE_PROPOSED]: ClosedLostReasonSingleValues.NO_ANSWER_DATE_PROPOSED,
    [DealNextStep.WAITING_FOR_SOMETHING_IN_GENERAL]:
        ClosedLostReasonSingleValues.NO_ANSWER_WAITING_FOR_SOMETHING_IN_GENERAL,
    [DealNextStep.SIGHTSEEING]: ClosedLostReasonSingleValues.NO_ANSWER_SIGHTSEEING,
    [DealNextStep.OTHER]: ClosedLostReasonSingleValues.NO_ANSWER_OTHER,
    [DealNextStep.FOLLOW_UP]: ClosedLostReasonSingleValues.NO_ANSWER_FOLLOW_UP,
    [DealNextStep.ARCHIVE]: ClosedLostReasonSingleValues.NO_ANSWER_ARCHIVE,
    [DealNextStep.CONTACT_DATA]: undefined,
    [DealNextStep.MAGIC_LINK]: undefined,
    [DealNextStep.NOT_REACHED]: undefined,
    [DealNextStep.CONTRACT]: undefined,
    [DealNextStep.CONSULTATION_PROTOCOL]: undefined,
};

export const POTENTIAL_HOMEOWNER_CLOSED_LOST_REASON_SINGLE_VALUES: ClosedLostReasonSingleValues[] = [
    ClosedLostReasonSingleValues.COMPETITION,
    ClosedLostReasonSingleValues.NO_BETTER_SOLUTION_FROM_US,
];

export const CONSULTATION_FINANCING_CHOICE_VALUES = Object.values(ConsultationFinancingChoice);

export const BORROWER_MAIN_RESIDENCE_TYPE_VALUES = Object.values(BorrowerMainResidenceType);

export const RELATED_OBJECT_TO_EXISTING_PROPERTY_ENUMERATOR_MAP: Record<
    RelatedObjectType,
    ExistingPropertyEnumerator | undefined
> = {
    [RelatedObjectType.FINANCING_OBJECT]: undefined,
    [RelatedObjectType.EXISTING_OBJECT_1]: "existingProperty1",
    [RelatedObjectType.EXISTING_OBJECT_2]: "existingProperty2",
    [RelatedObjectType.EXISTING_OBJECT_3]: "existingProperty3",
    [RelatedObjectType.EXISTING_OBJECT_4]: "existingProperty4",
};

export const EXISTING_PROPERTY_ENUMERATOR_TO_RELATED_OBJECT_MAP: Record<ExistingPropertyEnumerator, RelatedObjectType> =
    {
        existingProperty1: RelatedObjectType.EXISTING_OBJECT_1,
        existingProperty2: RelatedObjectType.EXISTING_OBJECT_2,
        existingProperty3: RelatedObjectType.EXISTING_OBJECT_3,
        existingProperty4: RelatedObjectType.EXISTING_OBJECT_4,
    };

export const HIGH_LTV_MARGIN = 95;
export const MEDIUM_LTV_MARGIN = 75;
export const HIGH_DSTI_MARGIN = 45;
export const MEDIUM_DSTI_MARGIN = 35;

export const DEAL_MULTI_VALUE_SEPARATOR = ";";
