import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { useLanguageFromQueryParams } from "src/shared/hooks/storage/useLanguageFromQueryParams";
import { DEFAULT_LANGUAGE, Language } from "../shared/types/language";
import { makeTranslator } from "../shared/util/translator";
import de from "../assets/de.json";

export type Translator = (key: string, variables?: { [key: string]: string | number }, fallback?: string) => string;

type LocaleContextData = {
    translator: Translator;
    language: Language;
    onLanguageChange: (value: Language) => void;
};

export type TranslationValue = {
    [key: string]: TranslationValue | string;
};

export type Translation = Partial<Record<Language, TranslationValue>>;

const initialProviderData: LocaleContextData = {
    translator: (key) => key,
    language: DEFAULT_LANGUAGE,
    onLanguageChange: (value) => {},
};

export const LocaleContext = React.createContext<LocaleContextData>(initialProviderData);

export const LocaleContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const [translation, setTranslation] = useState<any>(de);
    const { language, onLanguageChange } = useLanguageFromQueryParams();
    const translator = useMemo(() => makeTranslator(translation), [translation]);

    useEffect(() => {
        if (language === "en") {
            import("../assets/en.json").then((data) => setTranslation(data));
            return;
        }
        setTranslation(de);
    }, [language]);

    return (
        <LocaleContext.Provider value={{ translator, language, onLanguageChange }}>{children}</LocaleContext.Provider>
    );
};

export const useTranslation = () => {
    const props = useContext(LocaleContext);
    return { ...props, t: props.translator };
};
