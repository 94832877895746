import { Country } from "../models";

const ALL_COUNTRIES = [
    { label: "Afghanistan", value: Country.AFGHANISTAN },
    { label: "Ägypten", value: Country.EGYPT },
    { label: "Albanien", value: Country.ALBANIA },
    { label: "Algerien", value: Country.ALGERIA },
    { label: "Andorra", value: Country.ANDORRA },
    { label: "Angola", value: Country.ANGOLA },
    { label: "Antigua und Barbuda", value: Country.ANTIGUA_AND_BARBUDA },
    { label: "Äquatorialguinea", value: Country.EQUATORIAL_GUINEA },
    { label: "Argentinien", value: Country.ARGENTINA },
    { label: "Armenien", value: Country.ARMENIA },
    { label: "Aserbaidschan", value: Country.AZERBAIJAN },
    { label: "Äthiopien", value: Country.ETHIOPIA },
    { label: "Australien", value: Country.AUSTRALIA },
    { label: "Bahamas", value: Country.BAHAMAS },
    { label: "Bahrain", value: Country.BAHRAIN },
    { label: "Bangladesch", value: Country.BANGLADESH },
    { label: "Barbados", value: Country.BARBADOS },
    { label: "Belarus", value: Country.BELARUS },
    { label: "Belgien", value: Country.BELGIUM },
    { label: "Belize", value: Country.BELIZE },
    { label: "Benin", value: Country.BENIN },
    { label: "Bhutan", value: Country.BHUTAN },
    { label: "Bolivien", value: Country.BOLIVIA },
    { label: "Bosnien und Herzegowina", value: Country.BOSNIA_AND_HERZEGOVINA },
    { label: "Botsuana", value: Country.BOTSWANA },
    { label: "Brasilien", value: Country.BRAZIL },
    { label: "Brunei Darussalam", value: Country.BRUNEI_DARUSSALAM },
    { label: "Bulgarien", value: Country.BULGARIA },
    { label: "Burkina Faso", value: Country.BURKINA_FASO },
    { label: "Burundi", value: Country.BURUNDI },
    { label: "Cabo Verde", value: Country.CABO_VERDE },
    { label: "Chile", value: Country.CHILE },
    { label: "China", value: Country.CHINA },
    { label: "Cookinseln", value: Country.COOK_ISLANDS },
    { label: "Costa Rica", value: Country.COSTA_RICA },
    { label: "Côte d'Ivoire", value: Country.IVORY_COAST },
    { label: "Dänemark", value: Country.DENMARK },
    { label: "Deutschland", value: Country.GERMANY },
    { label: "Dominica", value: Country.DOMINICA },
    { label: "Dominikanische Republik", value: Country.DOMINICAN_REPUBLIC },
    { label: "Dschibuti", value: Country.DJIBOUTI },
    { label: "Ecuador", value: Country.ECUADOR },
    { label: "El Salvador", value: Country.EL_SALVADOR },
    { label: "Eritrea", value: Country.ERITREA },
    { label: "Estland", value: Country.ESTONIA },
    { label: "Eswatini", value: Country.ESWATINI },
    { label: "Fidschi", value: Country.FIJI },
    { label: "Finnland", value: Country.FINLAND },
    { label: "Frankreich", value: Country.FRANCE },
    { label: "Gabun", value: Country.GABON },
    { label: "Gambia", value: Country.GAMBIA },
    { label: "Georgien", value: Country.GEORGIA },
    { label: "Ghana", value: Country.GHANA },
    { label: "Grenada", value: Country.GRENADA },
    { label: "GREECE", value: Country.GREECE },
    { label: "Großbritannien", value: Country.UNITED_KINGDOM },
    { label: "Guatemala", value: Country.GUATEMALA },
    { label: "Guinea", value: Country.GUINEA },
    { label: "Guinea-Bissau", value: Country.GUINEA_BISSAU },
    { label: "Guyana", value: Country.GUYANA },
    { label: "Haiti", value: Country.HAITI },
    { label: "Honduras", value: Country.HONDURAS },
    { label: "Indien", value: Country.INDIA },
    { label: "Indonesien", value: Country.INDONESIA },
    { label: "Irak", value: Country.IRAQ },
    { label: "Iran", value: Country.IRAN },
    { label: "Irland", value: Country.IRELAND },
    { label: "Island", value: Country.ICELAND },
    { label: "Israel", value: Country.ISRAEL },
    { label: "Italien", value: Country.ITALY },
    { label: "Jamaika", value: Country.JAMAICA },
    { label: "Japan", value: Country.JAPAN },
    { label: "Jemen", value: Country.YEMEN },
    { label: "Jordanien", value: Country.JORDAN },
    { label: "Kambodscha", value: Country.CAMBODIA },
    { label: "Kamerun", value: Country.CAMEROON },
    { label: "Kanada", value: Country.CANADA },
    { label: "Kasachstan", value: Country.KAZAKHSTAN },
    { label: "Katar", value: Country.QATAR },
    { label: "Kenia", value: Country.KENYA },
    { label: "Kirgisistan", value: Country.KYRGYZSTAN },
    { label: "Kiribati", value: Country.KIRIBATI },
    { label: "Kolumbien", value: Country.COLOMBIA },
    { label: "Komoren", value: Country.COMOROS },
    { label: "Kongo", value: Country.CONGO },
    { label: "Kongo, Demokratische Republik", value: Country.DEMOCRATIC_REPUBLIC_OF_THE_CONGO },
    { label: "Korea, Demokratische Volksrepublik", value: Country.DEMOCRATIC_PEOPLES_REPUBLIC_OF_KOREA },
    { label: "Korea, Republik", value: Country.REPUBLIC_OF_KOREA },
    { label: "Kosovo", value: Country.KOSOVO },
    { label: "Kroatien", value: Country.CROATIA },
    { label: "Kuba", value: Country.CUBA },
    { label: "Kuwait", value: Country.KUWAIT },
    { label: "Laos", value: Country.LAOS },
    { label: "Lesotho", value: Country.LESOTHO },
    { label: "Lettland", value: Country.LATVIA },
    { label: "Libanon", value: Country.LEBANON },
    { label: "Liberia", value: Country.LIBERIA },
    { label: "Libyen", value: Country.LIBYA },
    { label: "Liechtenstein", value: Country.LIECHTENSTEIN },
    { label: "Litauen", value: Country.LITHUANIA },
    { label: "Luxemburg", value: Country.LUXEMBOURG },
    { label: "Madagaskar", value: Country.MADAGASCAR },
    { label: "Malawi", value: Country.MALAWI },
    { label: "Malaysia", value: Country.MALAYSIA },
    { label: "Malediven", value: Country.MALDIVES },
    { label: "Mali", value: Country.MALI },
    { label: "Malta", value: Country.MALTA },
    { label: "Marokko", value: Country.MOROCCO },
    { label: "Marshallinseln", value: Country.MARSHALL_ISLANDS },
    { label: "Mauretanien", value: Country.MAURITANIA },
    { label: "Mauritius", value: Country.MAURITIUS },
    { label: "Mexiko", value: Country.MEXICO },
    { label: "Mikronesien", value: Country.MICRONESIA },
    { label: "Moldau", value: Country.MOLDOVA },
    { label: "Monaco", value: Country.MONACO },
    { label: "Mongolei", value: Country.MONGOLIA },
    { label: "Montenegro", value: Country.MONTENEGRO },
    { label: "Mosambik", value: Country.MOZAMBIQUE },
    { label: "Myanmar", value: Country.MYANMAR },
    { label: "Namibia", value: Country.NAMIBIA },
    { label: "Nauru", value: Country.NAURU },
    { label: "Nepal", value: Country.NEPAL },
    { label: "Neuseeland", value: Country.NEW_ZEALAND },
    { label: "Nicaragua", value: Country.NICARAGUA },
    { label: "Niederlande", value: Country.NETHERLANDS },
    { label: "Niger", value: Country.NIGER },
    { label: "Nigeria", value: Country.NIGERIA },
    { label: "Nordmazedonien", value: Country.NORTH_MACEDONIA },
    { label: "Norwegen", value: Country.NORWAY },
    { label: "Oman", value: Country.OMAN },
    { label: "Österreich", value: Country.AUSTRIA, order: 1 },
    { label: "Pakistan", value: Country.PAKISTAN },
    { label: "Palau", value: Country.PALAU },
    { label: "Panama", value: Country.PANAMA },
    { label: "Papua-Neuguinea", value: Country.PAPUA_NEW_GUINEA },
    { label: "Paraguay", value: Country.PARAGUAY },
    { label: "Peru", value: Country.PERU },
    { label: "Philippinen", value: Country.PHILIPPINES },
    { label: "Polen", value: Country.POLAND },
    { label: "Portugal", value: Country.PORTUGAL },
    { label: "Ruanda", value: Country.RWANDA },
    { label: "Rumänien", value: Country.ROMANIA },
    { label: "Russische Föderation", value: Country.RUSSIAN_FEDERATION },
    { label: "Salomonen", value: Country.SOLOMON_ISLANDS },
    { label: "Sambia", value: Country.ZAMBIA },
    { label: "Samoa", value: Country.SAMOA },
    { label: "San Marino", value: Country.SAN_MARINO },
    { label: "São Tomé und Príncipe", value: Country.SAO_TOME_AND_PRINCIPE },
    { label: "Saudi-Arabien", value: Country.SAUDI_ARABIA },
    { label: "Schweden", value: Country.SWEDEN },
    { label: "Schweiz", value: Country.SWITZERLAND },
    { label: "Senegal", value: Country.SENEGAL },
    { label: "Serbien", value: Country.SERBIA },
    { label: "Seychellen", value: Country.SEYCHELLES },
    { label: "Sierra Leone", value: Country.SIERRA_LEONE },
    { label: "Simbabwe", value: Country.ZIMBABWE },
    { label: "Singapur", value: Country.SINGAPORE },
    { label: "Slowakei", value: Country.SLOVAKIA },
    { label: "Slowenien", value: Country.SLOVENIA },
    { label: "Somalia", value: Country.SOMALIA },
    { label: "Spanien", value: Country.SPAIN },
    { label: "Sri Lanka", value: Country.SRI_LANKA },
    { label: "St. Kitts und Nevis", value: Country.SAINT_KITTS_AND_NEVIS },
    { label: "St. Lucia", value: Country.SAINT_LUCIA },
    { label: "St. Vincent und die Grenadinen", value: Country.SAINT_VINCENT_AND_THE_GRENADINES },
    { label: "Südafrika", value: Country.SOUTH_AFRICA },
    { label: "Sudan", value: Country.SUDAN },
    { label: "Südsudan", value: Country.SOUTH_SUDAN },
    { label: "Suriname", value: Country.SURINAME },
    { label: "Syrien", value: Country.SYRIA },
    { label: "Tadschikistan", value: Country.TAJIKISTAN },
    { label: "Taiwan", value: Country.TAIWAN },
    { label: "Tansania", value: Country.TANZANIA },
    { label: "Thailand", value: Country.THAILAND },
    { label: "Timor-Leste", value: Country.TIMOR_LESTE },
    { label: "Togo", value: Country.TOGO },
    { label: "Tonga", value: Country.TONGA },
    { label: "Trinidad und Tobago", value: Country.TRINIDAD_AND_TOBAGO },
    { label: "Tschad", value: Country.CHAD },
    { label: "Tschechische Republik", value: Country.CZECH_REPUBLIC },
    { label: "Tunesien", value: Country.TUNISIA },
    { label: "Türkei", value: Country.TURKEY },
    { label: "Turkmenistan", value: Country.TURKMENISTAN },
    { label: "Tuvalu", value: Country.TUVALU },
    { label: "Uganda", value: Country.UGANDA },
    { label: "Ukraine", value: Country.UKRAINE },
    { label: "Ungarn", value: Country.HUNGARY },
    { label: "Uruguay", value: Country.URUGUAY },
    { label: "Usbekistan", value: Country.UZBEKISTAN },
    { label: "Vanuatu", value: Country.VANUATU },
    { label: "Vatikanstadt", value: Country.VATICAN_CITY },
    { label: "Venezuela", value: Country.VENEZUELA },
    { label: "Vereinigte Arabische Emirate", value: Country.UNITED_ARAB_EMIRATES },
    { label: "Vereinigte Staaten", value: Country.UNITED_STATES },
    { label: "Vietnam", value: Country.VIETNAM },
    { label: "Zentralafrikanische Republik", value: Country.CENTRAL_AFRICAN_REPUBLIC },
    { label: "Zypern", value: Country.CYPRUS },
];

export const EU_COUNTRY_VALUES = [
    Country.BELGIUM,
    Country.BULGARIA,
    Country.DENMARK,
    Country.GERMANY,
    Country.ESTONIA,
    Country.FINLAND,
    Country.FRANCE,
    Country.GREECE,
    Country.IRELAND,
    Country.ITALY,
    Country.CROATIA,
    Country.LATVIA,
    Country.LITHUANIA,
    Country.LUXEMBOURG,
    Country.MALTA,
    Country.NETHERLANDS,
    Country.AUSTRIA,
    Country.POLAND,
    Country.PORTUGAL,
    Country.ROMANIA,
    Country.SWEDEN,
    Country.SLOVAKIA,
    Country.SLOVENIA,
    Country.CZECH_REPUBLIC,
    Country.SPAIN,
    Country.HUNGARY,
    Country.CYPRUS,
];

const SORTED_COUNTRIES = ALL_COUNTRIES.sort((a, b) => (a.order || Infinity) - (b.order || Infinity));

export const ALL_COUNTRY_VALUES = SORTED_COUNTRIES.map((c) => c.value);

export const COUNTRY_VALUE_AUSTRIA = "Österreich";
