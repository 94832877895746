import { useCallback, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { AuthContext } from "src/context/AuthContext";
import { getAppData, GetAppDataResponse } from "src/service/app";
import { LoginStatus } from "../types/user";
import { APPLICATION_CUSTOMER_ROUTES } from "../constants/route";
import { useNavigator } from "./useNavigator";
import { ApplicationRoute } from "../types/route";

export function useMaintenance() {
    const location = useLocation();
    const [pathname, setPathname] = useState(location.pathname);
    const { navigate } = useNavigator();
    const { applicationUser, loginStatus } = useContext(AuthContext);

    const shouldCheckForMaintenance = useCallback(
        () =>
            loginStatus === LoginStatus.SUCCESS &&
            ![...APPLICATION_CUSTOMER_ROUTES, ApplicationRoute.MAINTENANCE].includes(pathname),
        [loginStatus, pathname],
    );

    const selectAppData = useCallback((res: GetAppDataResponse) => res.data.data, []);

    const { refetch } = useQuery({
        queryKey: ["maintenance"],
        queryFn: async () => getAppData(applicationUser),
        select: selectAppData,
        // To avoid data loss we will fetch maintenance mode only on initial app load and on route changes
        staleTime: Infinity,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        enabled: shouldCheckForMaintenance(),
        onSuccess: (data) => {
            if (data.isMaintenanceMode) {
                navigate(ApplicationRoute.MAINTENANCE);
            }
        },
    });

    // React router location triggers update even if query params change
    // This way we will only trigger update if the actual route changes
    useEffect(() => {
        if (location.pathname !== pathname) {
            setPathname(location.pathname);
        }
    }, [location, pathname, setPathname]);

    // Refetch maintenance on every ROUTE change
    useEffect(() => {
        shouldCheckForMaintenance() && refetch();
    }, [shouldCheckForMaintenance, refetch]);
}
