export enum AccountDetails {
    RAIFFEISEN = "Raiffeisen",
    BANK_AUSTRIA = "Bank Austria",
    ERSTE_BANK = "Erste Bank",
    BAWAG = "BAWAG",
    HYPO = "Hypo",
    EASY_BANK = "Easy Bank",
    OBERBANK = "Oberbank",
    SONSTIGES = "Sonstiges",
}

export enum DealPropertyRealEstateBathroomFacilityType {
    BATHTUB = "Badewanne",
    BIDET = "Bidet",
    DOUBLE_SINK = "Doppelwaschbecken",
    SHOWER = "Dusche",
    HAND_BASIN_IN_SEARATE_TOILET = "Handwaschbecken im getrennten WC",
    SEPARATE_UNDERFLOOR_HEATING = "Seperate Fußbodenheizung",
    TILING = "Verfliesung des gesamten Bades",
    WC = "WC",
    WELLNESS = "Wellnesselemente",
    WINDOW_TO_OPEN = "Zu öffnendes Fenster",
}

export enum DealPropertyRealEstateHeatingType {
    INDIVIDUAL_OVENS = "Einzelöfen",
    CENTRAL_HEATING = "Zentralheizung",
}

export enum DealPropertyRealEstateWinterGardenUsabilityType {
    HEATED = "Beheizbar",
    NOT_HEATED = "Nicht beheizbar",
}

export enum DealPropertyRealEstateTerraceUsabilityType {
    ROOF_TERRACE = "Dachterrasse",
    NOT_COVERED = "Nicht überdacht",
    COVERED = "Überdacht",
}

export enum DealPropertyRealEstateMaterialType {
    WOODEN_STRUCTURE_WITH_INSTALLATION = "Holzkonstruktion mit Installationsebene",
    WOODEN_STRUCTURE_WITHOUT_INSTALLATION = "Holzkonstruktion ohne Installationsebene",
    OTHER = "Sonstige Bauweise (Holzbau, Steinbau, etc.)",
    BRICK = "Ziegel/Beton",
}

export enum DealPropertyRealEstateGarageLocationType {
    ATTACHED_TO_RESIDENTIAL_BUILDING = "An Wohnhaus angebaut",
    DETACHED_ON_THE_PROPERTY = "Freistehend auf dem Grundstück",
}

export enum DealPropertyRealEstateGarageAdditionalInfoType {
    DIRECT_ACCESS_TO_HOUSE = "Direkter Zugang zum Wohnhaus",
    SOLID_CONSTRUCTION = "Massivbauweise",
    WATER_CONNECTION = "Wasseranschluss in der Garage",
    EXTERNAL_ACCESS_TO_HOUSE = "Überdachter Außenzugang zum Wohnhaus",
}

export enum DealPropertyRealEstateAtticType {
    SLOPING_ROOF = "Dachschräge vorhanden",
    FLAT_ROOF = "Flachdach",
    APARTMENT_NOT_IN_ATTIC = "Wohnung ist nicht im Dachgeschoss",
    RESIDENTIAL_USE = "Für Wohnzwecke genutzt/nutzbar",
    EXPANDABLE = "Ausbau möglich",
    NO_RESIDENTIAL_USE = "Nicht für Wohnzwecke nutzbar",
}
