import { MIN_LOW_INTENT_LOAN_AMOUNT } from "../../../utils/customer";
import {
    BorrowerEmploymentType,
    BorrowerReferralType,
    DealPropertyFinancingType,
    DealPropertyType,
    JaNeinValue,
    SurveyBankOption,
    SurveyFinancingType,
    SurveyHouseBankFinancingPreference,
    SurveyHowSoonFinancingCommitmentNeededRefinancing,
    SurveyProperties,
    SurveyPropertyUsage,
    SurveyPurchaseProgress,
    SurveyRefinancingProjectStatus,
} from "../../../models";

export const SURVEY_DEVELOPMENT_TEST_DATA_HIT_REFINANCING: Partial<SurveyProperties> = {
    intention: DealPropertyFinancingType.REFINANCE,
    financingType: SurveyFinancingType.REAL_ESTATE_CREDIT,
    progress: SurveyRefinancingProjectStatus.DEFINITELY_REFINANCING,
    propertyType: DealPropertyType.APARTMENT_PROPERTY_TYPE,
    usage: SurveyPropertyUsage.MAIN_RESIDENCE,
    refinancingDebtLeftAmount: 200_000,
    refinancingDebtRemainingYears: 10,
    whenWasPropertyBought: "2014",
    originalPurchaseOrConstructionPrice: 400_000,
    additionalInvestmentCosts: 0,
    currentMonthlyRate: 800,
    currentInterestRate: 5.5,
    goalOfRefinancing: "ziel",
    pax: "1 Person",
    primaryPersonNumberOfSalaries: 14,
    occupation: BorrowerEmploymentType.SELF_EMPLOYED,
    primaryPersonIncome: 4500,
    purchaseProgress: SurveyPurchaseProgress.BANK_APPOINTMENTS_PENDING,
    howSoonFinancingCommitmentNeeded: SurveyHowSoonFinancingCommitmentNeededRefinancing.ASAP_WANT_TO_REFINANCE,
    whichBanksAreHouseBanks: SurveyBankOption.ERSTE,
    houseBankFinancingPreference: SurveyHouseBankFinancingPreference.NONE,
    referral: BorrowerReferralType.GOOGLE,
    firstName: "Max",
    email: "test@miracl.at",
    consentedMarketingEmails: "false",
    phone: "123123123",
};

export const SURVEY_DEVELOPMENT_TEST_DATA_LIT_REFINANCING: Partial<SurveyProperties> = {
    intention: DealPropertyFinancingType.REFINANCE,
    financingType: SurveyFinancingType.REAL_ESTATE_CREDIT,
    progress: SurveyRefinancingProjectStatus.DEFINITELY_REFINANCING,
    propertyType: DealPropertyType.APARTMENT_PROPERTY_TYPE,
    usage: SurveyPropertyUsage.MAIN_RESIDENCE,
    refinancingDebtLeftAmount: MIN_LOW_INTENT_LOAN_AMOUNT,
    refinancingDebtRemainingYears: 10,
    whenWasPropertyBought: "2014",
    originalPurchaseOrConstructionPrice: 400_000,
    additionalInvestmentCosts: 0,
    currentMonthlyRate: 800,
    currentInterestRate: 5.2,
    goalOfRefinancing: "ziel",
    pax: "1 Person",
    primaryPersonNumberOfSalaries: 14,
    occupation: BorrowerEmploymentType.SELF_EMPLOYED,
    primaryPersonIncome: 4500,
    purchaseProgress: SurveyPurchaseProgress.BANK_APPOINTMENTS_PENDING,
    howSoonFinancingCommitmentNeeded:
        SurveyHowSoonFinancingCommitmentNeededRefinancing.NOT_YET_REFINANCING_NOT_CONFIRMED,
    whichBanksAreHouseBanks: SurveyBankOption.ERSTE,
    houseBankFinancingPreference: SurveyHouseBankFinancingPreference.NONE,
    phone: "123123123",
    firstName: "Max",
    email: "test@miracl.at",
    referral: BorrowerReferralType.GOOGLE,
    hasExistingBankOffer: JaNeinValue.JA,
    existingBankOfferPayoutAmount: 300000,
    existingBankOfferDocumentPath: "/existingBankOffers/testid",
};

export const SURVEY_DEVELOPMENT_TEST_DATA_UNFEASIBLE_REFINANCING: Partial<SurveyProperties> = {
    intention: DealPropertyFinancingType.REFINANCE,
    financingType: SurveyFinancingType.REAL_ESTATE_CREDIT,
    progress: SurveyRefinancingProjectStatus.DEFINITELY_REFINANCING,
    propertyType: DealPropertyType.APARTMENT_PROPERTY_TYPE,
    usage: SurveyPropertyUsage.MAIN_RESIDENCE,
    refinancingDebtLeftAmount: 200_000,
    refinancingDebtRemainingYears: 10,
    whenWasPropertyBought: "2014",
    originalPurchaseOrConstructionPrice: 400_000,
    additionalInvestmentCosts: 0,
    currentMonthlyRate: 800,
    currentInterestRate: 5.5,
    goalOfRefinancing: "ziel",
    pax: "1 Person",
    primaryPersonNumberOfSalaries: 14,
    occupation: BorrowerEmploymentType.SELF_EMPLOYED,
    primaryPersonIncome: 500,
    purchaseProgress: SurveyPurchaseProgress.BANK_APPOINTMENTS_PENDING,
    howSoonFinancingCommitmentNeeded: SurveyHowSoonFinancingCommitmentNeededRefinancing.ASAP_WANT_TO_REFINANCE,
    whichBanksAreHouseBanks: SurveyBankOption.ERSTE,
    houseBankFinancingPreference: SurveyHouseBankFinancingPreference.NONE,
    referral: BorrowerReferralType.GOOGLE,
    firstName: "Max",
    email: "test@miracl.at",
    consentedMarketingEmails: "false",
    phone: "123123123",
    hasExistingBankOffer: JaNeinValue.JA,
    existingBankOfferPayoutAmount: 300000,
    existingBankOfferDocumentPath: "/existingBankOffers/testid",
};

export const SURVEY_DEVELOPMENT_TEST_DATA_WRONG_REFINANCING: Partial<SurveyProperties> = {
    intention: DealPropertyFinancingType.REFINANCE,
    financingType: SurveyFinancingType.CONSUMER_CREDIT,
    progress: SurveyRefinancingProjectStatus.DEFINITELY_REFINANCING,
    propertyType: DealPropertyType.APARTMENT_PROPERTY_TYPE,
    usage: SurveyPropertyUsage.MAIN_RESIDENCE,
    refinancingDebtLeftAmount: 200_000,
    refinancingDebtRemainingYears: 10,
    whenWasPropertyBought: "2014",
    originalPurchaseOrConstructionPrice: 400_000,
    additionalInvestmentCosts: 0,
    currentMonthlyRate: 800,
    currentInterestRate: 5.5,
    goalOfRefinancing: "ziel",
    pax: "1 Person",
    primaryPersonNumberOfSalaries: 14,
    occupation: BorrowerEmploymentType.SELF_EMPLOYED,
    primaryPersonIncome: 4500,
    purchaseProgress: SurveyPurchaseProgress.BANK_APPOINTMENTS_PENDING,
    howSoonFinancingCommitmentNeeded: SurveyHowSoonFinancingCommitmentNeededRefinancing.ASAP_WANT_TO_REFINANCE,
    whichBanksAreHouseBanks: SurveyBankOption.ERSTE,
    houseBankFinancingPreference: SurveyHouseBankFinancingPreference.NONE,
    referral: BorrowerReferralType.GOOGLE,
};
