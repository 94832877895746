import { Grid, SxProps, TextField, TextFieldProps, Theme, Typography, useTheme } from "@mui/material";
import { ChangeEvent, Fragment, HTMLInputTypeAttribute, ReactNode, useState } from "react";
import { Control, Controller, Path, RegisterOptions } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { usePreventUpdateOnScroll } from "src/shared/hooks/form/usePreventUpdateOnScroll";
import { useTranslation } from "src/context/LocaleContext";

type NumberFormattingProps = {
    decimalSeparator?: string;
    thousandSeparator?: string;
    allowLeadingZeros?: boolean;
    fixedDecimalScale?: boolean;
    decimalScale?: number;
};

const defaultDecimalNumberFormattingProps: NumberFormattingProps = {
    decimalSeparator: ",",
    thousandSeparator: ".",
    allowLeadingZeros: false,
    fixedDecimalScale: true,
    decimalScale: 2,
};

const defaultWholeNumberFormattingProps: NumberFormattingProps = {
    decimalSeparator: ",",
    thousandSeparator: "",
    allowLeadingZeros: false,
    fixedDecimalScale: true,
    decimalScale: 0,
};

const getLabel = (focused: boolean, label: string | ReactNode, placeholder = null, required): string | ReactNode => {
    if (typeof label === "string") {
        let res = focused ? label : placeholder || label;
        res += required ? "*" : "";
        return res;
    }

    return (
        <>
            {label} {required ? "*" : ""}
        </>
    );
};

const getNumberFormatting = (isDecimal: boolean, formatting: NumberFormattingProps): NumberFormattingProps => {
    const defaultProps = isDecimal ? defaultDecimalNumberFormattingProps : defaultWholeNumberFormattingProps;

    return {
        ...defaultProps,
        ...formatting,
    };
};

const getSx = (theme: Theme, sx: SxProps, whiteBg: boolean): SxProps => {
    return {
        width: "100%",
        backgroundClip: "red",
        "&>label": { backgroundColor: "transparent" },

        ...(whiteBg && {
            "&>div": {
                backgroundColor: `${theme.palette.common.white} !important`,
            },
        }),
        ...sx,
    };
};

type ControlledTextFieldProps<TFormFields, TFieldType extends HTMLInputTypeAttribute> = {
    name: Path<TFormFields>;
    control: Control<TFormFields, any>;
    rules?: Omit<RegisterOptions<any, any>, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled">;
    type?: TFieldType;
    additionalOnChangeAction?: (value: string) => void;
    display?: boolean;
    formatAsDecimal?: TFieldType extends "number" ? boolean : false;
    numberFormattingProps?: NumberFormattingProps;
    requiredText?: string;
    whiteBg?: boolean;
    disableDefaultError?: boolean;
    minValue?: number;
    maxValue?: number;
} & TextFieldProps;

export const ControlledTextField = <T extends any, F extends HTMLInputTypeAttribute>({
    name,
    control,
    rules,
    additionalOnChangeAction,
    display = true,
    formatAsDecimal = false,
    numberFormattingProps,
    label = "",
    required = false,
    placeholder = "",
    onChange,
    onBlur,
    onFocus,
    requiredText = null,
    minValue = null,
    maxValue = null,
    whiteBg = false,
    disableDefaultError = false,
    type,
    ...rest
}: ControlledTextFieldProps<T, F>) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [focused, setFocused] = useState(false);
    const inputRef = usePreventUpdateOnScroll();
    const { variant, size, fullWidth, prefix, sx, disabled } = rest;

    if (!display) {
        return null;
    }

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({
                field: { value, onChange: controllerOnChange, onBlur: controllerOnBlur },
                fieldState: { error },
            }) => {
                const inputLabel = getLabel(focused, label, placeholder, required);
                const inputSx = getSx(theme, sx, whiteBg);
                const isNumber = type === "number";

                const inputOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
                    controllerOnBlur?.();
                    onBlur?.(event);
                    setFocused(false);
                };

                const inputOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
                    onFocus?.(event);
                    setFocused(true);
                };

                const numericOnChange = (v) => {
                    controllerOnChange(v.floatValue ?? "");
                    additionalOnChangeAction?.(String(v.floatValue));
                };

                const textualOnChange = (event: ChangeEvent<HTMLInputElement>) => {
                    const val = event.target.value;
                    const numberVal = Number(val);
                    onChange ? onChange(event) : controllerOnChange(isNumber && !isNaN(numberVal) ? numberVal : val);
                    additionalOnChangeAction?.(val);
                };

                const isRequiredErrorType = error?.type === "required" || error?.type === "optionality";

                return (
                    <Grid container flexDirection="column" sx={inputSx}>
                        {isNumber ? (
                            <NumericFormat
                                id={name}
                                inputRef={inputRef}
                                label={inputLabel}
                                value={value}
                                onValueChange={numericOnChange}
                                error={Boolean(error)}
                                onBlur={inputOnBlur}
                                onFocus={inputOnFocus}
                                variant={variant}
                                size={size}
                                fullWidth={fullWidth}
                                prefix={prefix}
                                customInput={TextField}
                                disabled={disabled}
                                inputProps={{
                                    inputMode: formatAsDecimal ? "decimal" : "numeric",
                                }}
                                InputProps={rest.InputProps}
                                sx={inputSx}
                                {...getNumberFormatting(formatAsDecimal, numberFormattingProps)}
                            />
                        ) : (
                            <TextField
                                id={name}
                                inputRef={inputRef}
                                label={inputLabel}
                                value={value ?? ""}
                                disabled={disabled}
                                error={Boolean(error)}
                                onChange={textualOnChange}
                                onBlur={inputOnBlur}
                                onFocus={inputOnFocus}
                                sx={inputSx}
                                type={type}
                                {...rest}
                            />
                        )}

                        {isRequiredErrorType && requiredText && (
                            <Typography color="red" fontSize={"12px !important"} marginTop={1}>
                                {requiredText}
                            </Typography>
                        )}
                        {error?.type === "min" && minValue && (
                            <Typography color="red" fontSize={"12px !important"} marginTop={1}>
                                {t("validation.fieldMinimum", { minimum: minValue })}
                            </Typography>
                        )}
                        {error?.type === "max" && maxValue && (
                            <Typography color="red" fontSize={"12px !important"} marginTop={1}>
                                {t("validation.fieldMaximum", { maximum: maxValue })}
                            </Typography>
                        )}
                        {!disableDefaultError && !!error && !isRequiredErrorType && error.message && (
                            <Typography color="red" fontSize={"12px !important"} marginTop={1}>
                                {error.message}
                            </Typography>
                        )}
                    </Grid>
                );
            }}
        />
    );
};
