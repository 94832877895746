import React, { FC, useContext } from "react";
import { useCallback } from "react";
import { AppSettings, DEFAULT_APP_SETTINGS } from "src/shared/types/settings";
import { useLocalStorage } from "src/shared/hooks/useLocalStorage";
import { LocalStorageKey } from "src/shared/types/storage";
import { AuthContext } from "./AuthContext";

type SettingsContextData = {
    appSettings: AppSettings;
    update: {
        appSettings: (value: AppSettings) => void;
    };
    toggle: {
        nextDocumentOnSave: () => void;
        bankNameVisible: () => void;
        displaySnowflakes: () => void;
        displayChristmasLights: () => void;
    };
};

const initialProviderData: SettingsContextData = {
    appSettings: {
        BANK_NAME_VISIBLE: false,
        NEXT_DOCUMENT_ON_SAVE: false,
        USE_EXACT_NUMBER_OF_BORROWERS_TEMPLATES: false,
        DISPLAY_CHRISTMAS_LIGHTS: true,
        DISPLAY_SNOWFLAKES: true,
    },
    update: {
        appSettings: (value: AppSettings) => null,
    },
    toggle: {
        nextDocumentOnSave: () => null,
        bankNameVisible: () => null,
        displaySnowflakes: () => null,
        displayChristmasLights: () => null,
    },
};

const SettingsContext = React.createContext<SettingsContextData>(initialProviderData);

export const SettingsContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const { applicationUser } = useContext(AuthContext);
    const { state, set } = useLocalStorage<AppSettings>(
        `${applicationUser?.email}-${LocalStorageKey.SETTINGS}`,
        true,
        DEFAULT_APP_SETTINGS,
    );

    const updateAppSettings = useCallback(
        (value: AppSettings) => {
            set(value);
        },
        [set],
    );

    const toggleNextDocumentOnSave = useCallback(() => {
        set({ ...state, NEXT_DOCUMENT_ON_SAVE: !state.NEXT_DOCUMENT_ON_SAVE });
    }, [set, state]);

    const toggleBankNameVisible = useCallback(() => {
        set({ ...state, BANK_NAME_VISIBLE: !state.BANK_NAME_VISIBLE });
    }, [set, state]);

    const toggleDisplaySnowflakes = useCallback(() => {
        set({ ...state, DISPLAY_SNOWFLAKES: !state.DISPLAY_SNOWFLAKES });
    }, [set, state]);

    const toggleDisplayChristmasLights = useCallback(() => {
        set({ ...state, DISPLAY_CHRISTMAS_LIGHTS: !state.DISPLAY_CHRISTMAS_LIGHTS });
    }, [set, state]);

    return (
        <SettingsContext.Provider
            value={{
                appSettings: state,
                update: {
                    appSettings: updateAppSettings,
                },
                toggle: {
                    nextDocumentOnSave: toggleNextDocumentOnSave,
                    bankNameVisible: toggleBankNameVisible,
                    displaySnowflakes: toggleDisplaySnowflakes,
                    displayChristmasLights: toggleDisplayChristmasLights,
                },
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};

export const useSettingsContext = () => useContext(SettingsContext);
