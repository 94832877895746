import { DealProperties, DealPropertiesHousehold, JaNeinValue } from "@miraclapp/mortgaging-shared";
import { UseFormReturn, useFieldArray } from "react-hook-form";

export const createDefaultHousehold = (): DealPropertiesHousehold => ({
    borrowerMembers: "",
    personMembers: "",
    numberOfChildren: 0,

    hasOtherChildren: "",
    numberOfOtherChildren: 0,
    childrenAges: "",
    otherChildrenAges: "",

    income: 0,
    rentalIncome: 0,
    alimonyIncome: 0,
    familyAllowanceIncome: 0,
    employmentIncome: 0,
    otherIncome: 0,

    hasCustomCosts: JaNeinValue.NEIN,

    // custom costs HAVE to be undefined by default
    customLivingCosts: undefined,
    customRentalAndOperatingCosts: undefined,
    customEnergyCosts: undefined,
    customVehicleCosts: undefined,
    customTransportationCosts: undefined,
    customInsuranceCosts: undefined,
    customTelephoneAndInternetCosts: undefined,
    customExistingLiabilitiesCosts: undefined,

    livingCosts: 0,
    rentalAndOperatingCosts: 0,
    energyCosts: 0,
    vehicleCosts: 0,
    transportationCosts: 0,
    insuranceCosts: 0,
    telephoneAndInternetCosts: 0,
    existingLiabilitiesCosts: 0,
    alimonyPayments: 0,
    otherCosts: 0,
    totalCosts: 0,
    safetyReserveAmount: 0,

    borrowers: [],
});

interface Props {
    form: UseFormReturn<DealProperties>;
}

export const useHouseholdsField = ({ form }: Props) => {
    const { control } = form;
    const { fields, append, remove } = useFieldArray({ control, name: "households" });

    const addHousehold = () => {
        append(createDefaultHousehold());
    };

    const removeHousehold = () => {
        remove(fields.length - 1);
    };

    return {
        householdsFields: fields,
        householdsCount: fields.length,
        addHousehold,
        removeHousehold,
    };
};
