import { ApplicationConfig } from "./types";

const environment = process.env.VITE_ENVIRONMENT as "development" | "testing" | "staging" | "production";
const projectId = process.env.VITE_PROJECT_ID;

const encryption = process.env.VITE_SERVER_ENCRYPTION;
const host = process.env.VITE_SERVER_HOST;
const internalHost = process.env.VITE_INTERNAL_SERVER_HOST;

const engineServerUseLocal = process.env.VITE_ENGINE_SERVER_USE_LOCAL;
const engineProjectId = process.env.VITE_ENGINE_PROJECT_ID;
const engineRegion = process.env.VITE_ENGINE_SERVER_REGION;
const engineEncryption = process.env.VITE_ENGINE_SERVER_ENCRYPTION;
const engineHost = process.env.VITE_ENGINE_SERVER_HOST;
const engineEncryptionLocal = process.env.VITE_ENGINE_SERVER_ENCRYPTION_LOCAL;
const engineHostLocal = process.env.VITE_ENGINE_SERVER_HOST_LOCAL;

const serverUrl = `${encryption}://${host}/api`;
const internalServerUrl = `${encryption}://${internalHost}/api`;

const specialistCommonPhoneNumber = process.env.VITE_SPECIALIST_COMMON_PHONE_NUMBER;

// Engine server URL configuration
let engineServerUrl = `${engineEncryption}://${engineRegion}-${engineProjectId}.${engineHost}/api`;

if (engineServerUseLocal === "true") {
    engineServerUrl = `${engineEncryptionLocal}://${engineHostLocal}/${engineProjectId}/${engineRegion}/api`;
}

const isFinlineProduction = projectId === "finline-mortgaging-production" && environment === "production";

export const config: ApplicationConfig = {
    environment,
    projectId,
    isFinlineProduction,
    server: {
        url: serverUrl,
        internalUrl: internalServerUrl,
    },
    engineServer: {
        url: engineServerUrl,
    },
    cookieHub: {
        code: process.env.VITE_COOKIEHUB_CODE,
        enabled: ["production", "staging"].includes(environment),
    },
    google: {
        apiKey: process.env.VITE_GOOGLE_API_KEY,
        trackingId: process.env.VITE_GTAG_TRACKING_ID,
        adsConversionId: {
            trackFinishLikeAppointment: true,

            appointmentPurchase: process.env.VITE_GOOGLE_ADS_CONVERSION_APPOINTMENT_PURCHASE,
            finishPurchase: process.env.VITE_GOOGLE_ADS_CONVERSION_FINISH_PURCHASE,
            segmentedPurchase: process.env.VITE_GOOGLE_ADS_CONVERSION_SEGMENTED_PURCHASE,
            interest: {
                purchase: process.env.VITE_GOOGLE_ADS_CONVERSION_INTEREST_PURCHASE,
                refinancing: process.env.VITE_GOOGLE_ADS_CONVERSION_INTEREST_REFINANCING,
                construction: process.env.VITE_GOOGLE_ADS_CONVERSION_INTEREST_CONSTRUCTION,
            },
        },
    },
    facebook: {
        pixelId: process.env.VITE_PIXEL_ID,
    },
    maxFileSize: 100000000, // 100MB
    featureFlags: {
        DISPLAY_DETAILED_MONTHLY_INCOMES: false,
        DISPLAY_PEOPLE_FORM: true,
    },
    dev: {
        theme: {
            holidayThemeEnabled: true,
        },
    },
    common: {
        specialistCommonPhoneNumber,
    },
    survey: {
        abTestVariantRatio: 0,
        storageKey: "survey_variant",
    },
    hotjar: {
        id: parseInt(process.env.VITE_HOTJAR_ID),
        enabled: false, // environment === "production",
        version: 6,
    },
};
