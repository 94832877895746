import { ApiResponse, Session } from "@miraclapp/mortgaging-shared";
import { AxiosResponse } from "axios";
import { User } from "firebase/auth";
import { config } from "../../config";
import { axiosApiInstance } from "../api/axiosApiInstance";
import { buildInternalRequestConfig } from "../api/helpers";

const base = `${config.server.url}`;
type LoginWithTokensApiResponseData = Partial<Session>;
type LoginWithTokensApiResponse = ApiResponse<LoginWithTokensApiResponseData>;
type LoginWithTokensResponse = AxiosResponse<ApiResponse<LoginWithTokensApiResponseData>>;

// Hubspot appends unknown characters on the login token starting with %,
// so this function is used to extract the token only
export const formatLoginToken = (token: string) => {
    const hubspotStartIndex = token.indexOf("%");
    if (hubspotStartIndex === -1) return token;
    return token.slice(0, hubspotStartIndex);
};

export const loginWithTokensAndIds = async ({
    applicationUser,
    dealId,
    loginToken,
    tipsterId,
}: {
    applicationUser: User;
    dealId?: string;
    tipsterId?: string;
    loginToken: string;
}): Promise<LoginWithTokensResponse> => {
    let queryParams = "";

    if (loginToken) {
        queryParams = `?loginToken=${formatLoginToken(loginToken)}`;
    }

    if (dealId) {
        queryParams = `${queryParams}&dealId=${dealId}`;
    }

    if (tipsterId) {
        queryParams = `${queryParams}&tipsterId=${tipsterId}`;
    }

    const axiosConfig = await buildInternalRequestConfig(applicationUser);
    return await axiosApiInstance.get<LoginWithTokensApiResponse>(`${base}/auth/login${queryParams}`, axiosConfig);
};

type RefreshTokenApiResponseData = LoginWithTokensResponse;
export const refreshSessionToken = async (refreshToken: string): Promise<RefreshTokenApiResponseData> => {
    return await axiosApiInstance.post(`${base}/auth/refresh`, { refreshToken }, { withCredentials: false });
};

type LoginData = {
    email: string;
};

type LoginResponse = Promise<{
    data: { accessToken: string };
}>;

export async function login(data: LoginData): LoginResponse {
    return await axiosApiInstance.post(`${base}/auth/login`, data);
}
