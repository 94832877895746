import { DEAL_MULTI_VALUE_SEPARATOR } from "@miraclapp/mortgaging-shared";
import { BankIdentifierProtocol } from "@miraclapp/mortgaging-shared";
import { DEFAULT_SUPPORTED_BANKS } from "../constants/bank";

export function parseCompanyBanks(value: string): BankIdentifierProtocol[] {
    if (!value) {
        return DEFAULT_SUPPORTED_BANKS;
    }

    return value.split(DEAL_MULTI_VALUE_SEPARATOR) as BankIdentifierProtocol[];
}
