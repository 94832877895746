export enum Role {
    BASIC_USER = "BASIC_USER",
    SUPER_USER = "SUPER_USER",
    DEVELOPER = "DEVELOPER",
    CUSTOMER = "CUSTOMER",
    SURVEY_USER = "SURVEY_USER",
    COMPANY = "COMPANY",
    TIPSTER = "TIPSTER",
}

export enum Privilege {
    VIEW_DEAL_DATA_HISTORY = "VIEW_DEAL_DATA_HISTORY",
}

export type RolePrivileges = {
    role: Role;
    privileges: Privilege[];
};
