import { AxiosResponse } from "axios";
import { User } from "firebase/auth";
import { buildInternalRequestConfig } from "../api/helpers";
import { axiosApiInstance } from "../api/axiosApiInstance";
import { config as appConfig } from "../../config";

export type GetAppData = {
    isMaintenanceMode: boolean;
};

export type GetAppDataResponse = AxiosResponse<{ data: GetAppData }>;

const base = `${appConfig.server.url}`;

export const getAppData = async (currentUser: User): Promise<GetAppDataResponse> => {
    const config = await buildInternalRequestConfig(currentUser);
    return axiosApiInstance.get(`${base}/app`, config);
};
