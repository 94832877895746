import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useTranslation } from "src/context/LocaleContext";

type VitePreloadErrorDialogProps = {
    open: boolean;
};

export function VitePreloadErrorDialog({ open }: VitePreloadErrorDialogProps) {
    const { t } = useTranslation();

    const onConfirm = () => {
        window.location.reload();
    };

    return (
        <Dialog open={open}>
            <DialogTitle sx={{ fontSize: "20px" }}>{t("app.vitePreloadErrorDialog.title")}</DialogTitle>
            <DialogContent>
                <Typography sx={{ fontSize: "16px" }}>{t("app.vitePreloadErrorDialog.content")}</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onConfirm}>
                    {t("app.vitePreloadErrorDialog.cta")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
