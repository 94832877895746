import { DealPropertyConditionType } from "../models";
import {
    DealPropertyApartmentCurrentUse,
    DealPropertyBuildingMaterialType,
    DealPropertyConnectionType,
    DealPropertyEntireHouseConditionType,
    DealPropertyEnvironmentalImpactType,
    DealPropertyGarageSpaceType,
    DealPropertyInclinationType,
    DealPropertyParcelType,
    DealPropertyParkingSpaceType,
    DealPropertyThermalInsulationType,
} from "../models/deal/bank/erste";

export const PROPERTY_TYPE_OF_PARCEL_OPTIONS: string[] = [
    DealPropertyParcelType.MIDDLE,
    DealPropertyParcelType.CORNER,
    DealPropertyParcelType.FLAG,
];

export const PROPERTY_INCLINATION_OPTIONS: string[] = [
    DealPropertyInclinationType.EVEN,
    DealPropertyInclinationType.INCLINED,
    DealPropertyInclinationType.STEEP,
];

export const PROPERTY_HAS_CONNECTION_TO_OPTIONS: string[] = [
    DealPropertyConnectionType.ELECTRICTIY,
    DealPropertyConnectionType.WATER,
    DealPropertyConnectionType.GAS,
    DealPropertyConnectionType.SEWER,
    DealPropertyConnectionType.DISTRICT_HEATING,
    DealPropertyConnectionType.PHOTOVOLTAICS,
    DealPropertyConnectionType.OTHER,
];

export const PROPERTY_ENVIRONMENTAL_IMPACT_OPTIONS: string[] = [
    DealPropertyEnvironmentalImpactType.NONE,
    DealPropertyEnvironmentalImpactType.EXISTS,
];

export const PROPERTY_THERMAL_INSULATION_OPTIONS: string[] = [
    DealPropertyThermalInsulationType.CORRESPONDING_TO_BUILDING_AGE,
    DealPropertyThermalInsulationType.OTHER,
];

export const PROPERTY_BUILDING_MATERIAL_OPTIONS: string[] = [
    DealPropertyBuildingMaterialType.NO_ENDANGERMENT,
    DealPropertyBuildingMaterialType.LOW_ENDANGERMENT,
    DealPropertyBuildingMaterialType.HIGH_ENDANGERMENT,
];

export const PROPERTY_CONDITION_OPTIONS: string[] = [
    DealPropertyConditionType.NORMAL,
    DealPropertyConditionType.GOOD,
    DealPropertyConditionType.USED,
    DealPropertyConditionType.VACANT,
    DealPropertyConditionType.NOT_HABITABLE,
];

export const APARTMENT_POSITION_FLOOR = "Stockwerk";
export const APARTMENT_POSITION_OPTIONS: string[] = [
    "Erdgeschoss",
    APARTMENT_POSITION_FLOOR,
    "Letztes Obergeschoss/Dachgeschoss",
];
export const APARTMENT_CONDITION_OPTIONS: string[] = ["normal (enstpricht dem Standard)", "gut", "abgewohnt/veraltet"];

export const APARTMENT_OPERATING_COSTS_INCLUDE: string[] = ["Heizkosten in BK inkl.", "Instandhaltung in BK inkl."];

export const APARTMENT_CURRENT_USE_OPTIONS: string[] = [
    DealPropertyApartmentCurrentUse.OWN_USE,
    DealPropertyApartmentCurrentUse.RENTED,
    DealPropertyApartmentCurrentUse.VACANT,
    DealPropertyApartmentCurrentUse.UNDER_WORKS,
];

export const APARTMENT_GARAGE_SPACE_TYPE_OPTIONS: string[] = [
    DealPropertyGarageSpaceType.RENT,
    DealPropertyGarageSpaceType.OWNED,
];

export const APARTMENT_PARKING_SPACE_TYPE_OPTIONS: string[] = [
    DealPropertyParkingSpaceType.RENT,
    DealPropertyParkingSpaceType.OWNED,
];

export const PROPERTY_CONDITION_OF_THE_ENTIRE_HOUSE_OPTIONS: string[] = [
    DealPropertyEntireHouseConditionType.BACKLOG,
    DealPropertyEntireHouseConditionType.CONTINUOUSLY_RENOVATED,
    DealPropertyEntireHouseConditionType.NORMAL,
    DealPropertyEntireHouseConditionType.NEW,
];

export const HOUSE_BASEMENT_OPTIONS: string[] = [
    "Lagerkeller einfach",
    "Lagerkeller hochwertig",
    "Keller mit Wohnnutzeignung",
];
