import {
    Role,
    RolePrivileges,
    CUSTOMER_ROLES,
    Privilege,
    Specialist,
    LoginErrorCode,
    SignupErrorCode,
    MIRACL_COMPANY_ID,
    BankIdentifierProtocol,
} from "@miraclapp/mortgaging-shared";
import { User } from "firebase/auth";
import React, { FC, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { getPrivileges, getPrivilegesByRole } from "src/service/role/api";
import { sentryService } from "src/service/sentry";
import { config } from "../config";
import { AuthServiceInstance, CredentialsLoginData, LoginOptions } from "../service/google/firebase";
import { LoginStatus, SignupStatus } from "../shared/types/user";
import { initializeAxiosApiInstance } from "src/service/api/axiosApiInstance";
import { ApplicationRoute } from "src/shared/types/route";
import { useLocation } from "react-router-dom";
import { useAuthenticateBySessionToken } from "src/shared/hooks/useAuthenticateBySessionToken";
import { useNavigator } from "src/shared/hooks/useNavigator";
import { getUserProfile } from "src/service/profile/api";
import { AUTHORIZED_LOGIN_ROLES } from "src/shared/constants/auth";
import { canUserSignUp } from "src/service/users/api";
import { getCompanyLogoUrl } from "src/service/companies/api";
import { parseCompanyBanks } from "src/shared/util/company";
import { DEFAULT_SUPPORTED_BANKS } from "src/shared/constants/bank";

type AuthContextData = {
    profile: Specialist;
    isMiraclCompany: boolean;
    companyBanks: BankIdentifierProtocol[];
    applicationUser: User;
    rolePrivileges: RolePrivileges;
    switchUserRole: (role: Role) => void;
    login: (options: LoginOptions) => Promise<void>;
    signup: (options: CredentialsLoginData) => Promise<void>;
    logout: () => void;
    loginStatus: LoginStatus;
    setLoginStatus: React.Dispatch<SetStateAction<LoginStatus>>;
    tokenLoginStatus: LoginStatus;
    setTokenLoginStatus: React.Dispatch<SetStateAction<LoginStatus>>;
    setRolePrivileges: React.Dispatch<SetStateAction<RolePrivileges>>;
    loginViaTokenError: boolean;
    setLoginViaTokenError: React.Dispatch<SetStateAction<boolean>>;
    isCustomerUser: boolean;
    signupStatus: SignupStatus;
    signupErrorCode: SignupErrorCode;
    loginErrorCode: LoginErrorCode;
    sessionToken: string | null;
    setSessionToken: React.Dispatch<SetStateAction<string | null>>;
};

export const AuthContext = React.createContext<AuthContextData>({
    profile: undefined,
    isMiraclCompany: false,
    companyBanks: DEFAULT_SUPPORTED_BANKS,
    applicationUser: undefined,
    rolePrivileges: undefined,
    switchUserRole: async (role: Role) => {},
    login: async () => {},
    logout: () => {},
    signup: async () => {},
    loginStatus: undefined,
    setLoginStatus: undefined,
    tokenLoginStatus: undefined,
    setTokenLoginStatus: undefined,
    setRolePrivileges: undefined,
    loginViaTokenError: undefined,
    setLoginViaTokenError: undefined,
    isCustomerUser: undefined,
    signupStatus: undefined,
    signupErrorCode: undefined,
    loginErrorCode: undefined,
    sessionToken: null,
    setSessionToken: () => {},
});

export const DEFAULT_DEVELOPMENT_ROLE_PRIVILEGES: RolePrivileges = {
    role: Role.DEVELOPER,
    privileges: Object.values(Privilege),
};

export const DEFAULT_ROLE_PRIVILEGES: RolePrivileges = {
    role: Role.CUSTOMER,
    privileges: [],
};

export const AuthContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const isDevelopment = config.environment === "development";
    const { pathname } = useLocation();
    const { navigate } = useNavigator();
    const [applicationUser, setApplicationUser] = useState<User>();
    const [loginStatus, setLoginStatus] = useState<LoginStatus>(LoginStatus.LOADING);
    const [signupStatus, setSignupStatus] = useState<SignupStatus>(SignupStatus.INITIAL);
    const [signupErrorCode, setSignupErrorCode] = useState<SignupErrorCode | null>(null);
    const [loginErrorCode, setLoginErrorCode] = useState<LoginErrorCode | null>(null);
    const [rolePrivileges, setRolePrivileges] = useState<RolePrivileges>(DEFAULT_ROLE_PRIVILEGES);
    const isCustomerUser = useMemo(() => CUSTOMER_ROLES.includes(rolePrivileges.role), [rolePrivileges]);
    const [sessionToken, setSessionToken] = useState<string | null>(null);
    const [profile, setProfile] = useState<Specialist | null>(null);

    const isMiraclCompany = profile?.companyId === MIRACL_COMPANY_ID;

    const companyBanks = useMemo(() => {
        return parseCompanyBanks(profile?.company?.supportedBanks);
    }, [profile?.company?.supportedBanks]);

    const [roleSelected, setRoleSelected] = useState(false);

    const { tokenLoginStatus, setTokenLoginStatus, loginViaTokenError, setLoginViaTokenError } =
        useAuthenticateBySessionToken(applicationUser, setRolePrivileges, setSessionToken);

    const login = useCallback(async (options: LoginOptions) => {
        try {
            await AuthServiceInstance.login(options);
            setLoginErrorCode(null);
        } catch (error) {
            if (Object.values(LoginErrorCode).includes(error.code)) {
                setLoginErrorCode(error.code);
            }
            sentryService.report(error);
            setLoginStatus(LoginStatus.ERROR);
        }
    }, []);

    const signup = async (credentials: CredentialsLoginData) => {
        try {
            await canUserSignUp(credentials.email);
            await AuthServiceInstance.signup(credentials);
            setSignupStatus(SignupStatus.SUCCESS);
            navigate(ApplicationRoute.HOME);
        } catch (error) {
            setSignupErrorCode(error.response?.data?.code || error.code);
            sentryService.report(error);
            setSignupStatus(SignupStatus.ERROR);
        }
    };

    const logout = useCallback(async () => {
        try {
            await AuthServiceInstance.logout();

            setApplicationUser(null);
            setProfile(null);

            setRolePrivileges(DEFAULT_ROLE_PRIVILEGES);
            setRoleSelected(false);

            setLoginStatus(LoginStatus.LOGGED_OUT);
        } catch (error) {
            sentryService.report(error);
            setLoginStatus(LoginStatus.ERROR);
        }
    }, []);

    const fetchUserPrivileges = useCallback(
        async (user: User) => {
            try {
                const response = await getPrivileges(user);
                if (!response.data.success) {
                    throw new Error(`Error retrieving user privileges. User email: ${user.email}`);
                }

                setRolePrivileges(response.data.data);
                setRoleSelected(true);
                return response.data.data;
            } catch (error) {
                sentryService.report(error);
                await logout();
                return null;
            }
        },
        [setRolePrivileges, setRoleSelected, logout],
    );

    const switchUserRole = useCallback(
        async (role: Role) => {
            if (rolePrivileges.role === role) {
                return;
            }

            try {
                const response = await getPrivilegesByRole(applicationUser, role);

                if (!response.data.success) {
                    throw new Error(`Error retrieving user privileges by role. User email: ${applicationUser.email}`);
                }
                setRolePrivileges({
                    role,
                    privileges: response?.data?.data,
                });

                setRoleSelected(true);
            } catch (error) {
                sentryService.report(error);
            }
        },
        [applicationUser, rolePrivileges, setRoleSelected],
    );

    const handleLoginSuccess = useCallback(
        (user: User) => {
            const isLoginPath = pathname?.includes(ApplicationRoute.LOGIN);

            setLoginStatus(LoginStatus.SUCCESS);
            setApplicationUser(user);

            if (isLoginPath) {
                navigate(ApplicationRoute.HOME);
            }
        },
        [pathname, navigate],
    );

    const handleLoginUnauthorized = useCallback(() => {
        setLoginStatus(LoginStatus.UNAUTHORIZED);
        setRolePrivileges(DEFAULT_ROLE_PRIVILEGES);
    }, []);

    const fetchProfile = async (user: User) => {
        const res = await getUserProfile(user);
        if (res.data?.company?.id) {
            const companyLogo = await getCompanyLogoUrl(user, res.data.company.id);
            setProfile({ ...res.data, company: { ...res.data.company, logo: companyLogo } });
        } else if (res.data) {
            setProfile(res.data);
        }
    };

    const authStageChangeHandler = useCallback(
        async (user) => {
            try {
                setLoginStatus(LoginStatus.LOADING);

                // If the user is not defined
                // it means that the user is logged out
                if (!user) {
                    setApplicationUser(undefined);
                    setLoginStatus(LoginStatus.LOGGED_OUT);
                    setRolePrivileges(DEFAULT_ROLE_PRIVILEGES);
                    return;
                }

                // Checking for firebase availability
                // If not available we log out possible remaining session
                if (!(await AuthServiceInstance.available(user))) {
                    await logout();
                    return;
                }

                // Allow any email address while development is active
                if (isDevelopment && user?.email && user?.emailVerified) {
                    if (!roleSelected) {
                        await fetchUserPrivileges(user);
                    }
                    await fetchProfile(user);
                    handleLoginSuccess(user);
                    return;
                }

                const privileges = await fetchUserPrivileges(user);

                if (AUTHORIZED_LOGIN_ROLES.includes(privileges?.role)) {
                    await fetchProfile(user);
                    await handleLoginSuccess(user);
                } else {
                    handleLoginUnauthorized();
                }
            } catch (error) {
                sentryService.report(error);
                setApplicationUser(undefined);
                setLoginStatus(LoginStatus.ERROR);
                setRolePrivileges(DEFAULT_ROLE_PRIVILEGES);
            }
        },
        [fetchUserPrivileges, handleLoginSuccess, handleLoginUnauthorized, logout, isDevelopment, roleSelected],
    );

    /**
     * Handler for managing the auth state of the application
     * Reacts to firebase user changing state after login/logout
     * Reloading the user to make a request to the server and prevent
     * app loading when the firebase services are not active
     */
    useEffect(() => {
        const authUnsubscriber = AuthServiceInstance.getAuth().onAuthStateChanged(authStageChangeHandler);

        return () => authUnsubscriber();
    }, [authStageChangeHandler]);

    useEffect(() => {
        initializeAxiosApiInstance(() => {
            setApplicationUser(undefined);
            setLoginStatus(LoginStatus.ERROR);
            navigate(ApplicationRoute.LOGIN);
        });
    }, [navigate]);

    return (
        <AuthContext.Provider
            value={{
                login,
                applicationUser,
                companyBanks,
                logout,
                loginStatus,
                rolePrivileges,
                setLoginStatus,
                switchUserRole,
                setTokenLoginStatus,
                tokenLoginStatus,
                setRolePrivileges,
                loginViaTokenError,
                setLoginViaTokenError,
                isCustomerUser,
                profile,
                isMiraclCompany,
                signup,
                signupStatus,
                signupErrorCode,
                loginErrorCode,
                sessionToken,
                setSessionToken,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
