export enum Country {
    AFGHANISTAN = "Afghanistan",
    EGYPT = "Ägypten",
    ALBANIA = "Albanien",
    ALGERIA = "Algerien",
    ANDORRA = "Andorra",
    ANGOLA = "Angola",
    ANTIGUA_AND_BARBUDA = "Antigua und Barbuda",
    EQUATORIAL_GUINEA = "Äquatorialguinea",
    ARGENTINA = "Argentinien",
    ARMENIA = "Armenien",
    AZERBAIJAN = "Aserbaidschan",
    ETHIOPIA = "Äthiopien",
    AUSTRALIA = "Australien",
    BAHAMAS = "Bahamas",
    BAHRAIN = "Bahrain",
    BANGLADESH = "Bangladesch",
    BARBADOS = "Barbados",
    BELARUS = "Belarus",
    BELGIUM = "Belgien",
    BELIZE = "Belize",
    BENIN = "Benin",
    BHUTAN = "Bhutan",
    BOLIVIA = "Bolivien",
    BOSNIA_AND_HERZEGOVINA = "Bosnien und Herzegowina",
    BOTSWANA = "Botsuana",
    BRAZIL = "Brasilien",
    BRUNEI_DARUSSALAM = "Brunei Darussalam",
    BULGARIA = "Bulgarien",
    BURKINA_FASO = "Burkina Faso",
    BURUNDI = "Burundi",
    CABO_VERDE = "Cabo Verde",
    CHILE = "Chile",
    CHINA = "China",
    COOK_ISLANDS = "Cookinseln",
    COSTA_RICA = "Costa Rica",
    IVORY_COAST = "Côte d'Ivoire",
    DENMARK = "Dänemark",
    GERMANY = "Deutschland",
    DOMINICA = "Dominica",
    DOMINICAN_REPUBLIC = "Dominikanische Republik",
    DJIBOUTI = "Dschibuti",
    ECUADOR = "Ecuador",
    EL_SALVADOR = "El Salvador",
    ERITREA = "Eritrea",
    ESTONIA = "Estland",
    ESWATINI = "Eswatini",
    FIJI = "Fidschi",
    FINLAND = "Finnland",
    FRANCE = "Frankreich",
    GABON = "Gabun",
    GAMBIA = "Gambia",
    GEORGIA = "Georgien",
    GHANA = "Ghana",
    GRENADA = "Grenada",
    GREECE = "Griechenland",
    UNITED_KINGDOM = "Großbritannien",
    GUATEMALA = "Guatemala",
    GUINEA = "Guinea",
    GUINEA_BISSAU = "Guinea-Bissau",
    GUYANA = "Guyana",
    HAITI = "Haiti",
    HONDURAS = "Honduras",
    INDIA = "Indien",
    INDONESIA = "Indonesien",
    IRAQ = "Irak",
    IRAN = "Iran",
    IRELAND = "Irland",
    ICELAND = "Island",
    ISRAEL = "Israel",
    ITALY = "Italien",
    JAMAICA = "Jamaika",
    JAPAN = "Japan",
    YEMEN = "Jemen",
    JORDAN = "Jordanien",
    CAMBODIA = "Kambodscha",
    CAMEROON = "Kamerun",
    CANADA = "Kanada",
    KAZAKHSTAN = "Kasachstan",
    QATAR = "Katar",
    KENYA = "Kenia",
    KYRGYZSTAN = "Kirgisistan",
    KIRIBATI = "Kiribati",
    COLOMBIA = "Kolumbien",
    COMOROS = "Komoren",
    CONGO = "Kongo",
    DEMOCRATIC_REPUBLIC_OF_THE_CONGO = "Kongo, Demokratische Republik",
    DEMOCRATIC_PEOPLES_REPUBLIC_OF_KOREA = "Korea, Demokratische Volksrepublik",
    REPUBLIC_OF_KOREA = "Korea, Republik",
    KOSOVO = "Kosovo",
    CROATIA = "Kroatien",
    CUBA = "Kuba",
    KUWAIT = "Kuwait",
    LAOS = "Laos",
    LESOTHO = "Lesotho",
    LATVIA = "Lettland",
    LEBANON = "Libanon",
    LIBERIA = "Liberia",
    LIBYA = "Libyen",
    LIECHTENSTEIN = "Liechtenstein",
    LITHUANIA = "Litauen",
    LUXEMBOURG = "Luxemburg",
    MADAGASCAR = "Madagaskar",
    MALAWI = "Malawi",
    MALAYSIA = "Malaysia",
    MALDIVES = "Malediven",
    MALI = "Mali",
    MALTA = "Malta",
    MOROCCO = "Marokko",
    MARSHALL_ISLANDS = "Marshallinseln",
    MAURITANIA = "Mauretanien",
    MAURITIUS = "Mauritius",
    MEXICO = "Mexiko",
    MICRONESIA = "Mikronesien",
    MOLDOVA = "Moldau",
    MONACO = "Monaco",
    MONGOLIA = "Mongolei",
    MONTENEGRO = "Montenegro",
    MOZAMBIQUE = "Mosambik",
    MYANMAR = "Myanmar",
    NAMIBIA = "Namibia",
    NAURU = "Nauru",
    NEPAL = "Nepal",
    NEW_ZEALAND = "Neuseeland",
    NICARAGUA = "Nicaragua",
    NETHERLANDS = "Niederlande",
    NIGER = "Niger",
    NIGERIA = "Nigeria",
    NORTH_MACEDONIA = "Nordmazedonien",
    NORWAY = "Norwegen",
    OMAN = "Oman",
    AUSTRIA = "Österreich",
    PAKISTAN = "Pakistan",
    PALAU = "Palau",
    PANAMA = "Panama",
    PAPUA_NEW_GUINEA = "Papua-Neuguinea",
    PARAGUAY = "Paraguay",
    PERU = "Peru",
    PHILIPPINES = "Philippinen",
    POLAND = "Polen",
    PORTUGAL = "Portugal",
    RWANDA = "Ruanda",
    ROMANIA = "Rumänien",
    RUSSIAN_FEDERATION = "Russische Föderation",
    SOLOMON_ISLANDS = "Salomonen",
    ZAMBIA = "Sambia",
    SAMOA = "Samoa",
    SAN_MARINO = "San Marino",
    SAO_TOME_AND_PRINCIPE = "São Tomé und Príncipe",
    SAUDI_ARABIA = "Saudi-Arabien",
    SWEDEN = "Schweden",
    SWITZERLAND = "Schweiz",
    SENEGAL = "Senegal",
    SERBIA = "Serbien",
    SEYCHELLES = "Seychellen",
    SIERRA_LEONE = "Sierra Leone",
    ZIMBABWE = "Simbabwe",
    SINGAPORE = "Singapur",
    SLOVAKIA = "Slowakei",
    SLOVENIA = "Slowenien",
    SOMALIA = "Somalia",
    SPAIN = "Spanien",
    SRI_LANKA = "Sri Lanka",
    SAINT_KITTS_AND_NEVIS = "St. Kitts und Nevis",
    SAINT_LUCIA = "St. Lucia",
    SAINT_VINCENT_AND_THE_GRENADINES = "St. Vincent und die Grenadinen",
    SOUTH_AFRICA = "Südafrika",
    SUDAN = "Sudan",
    SOUTH_SUDAN = "Südsudan",
    SURINAME = "Suriname",
    SYRIA = "Syrien",
    TAJIKISTAN = "Tadschikistan",
    TAIWAN = "Taiwan",
    TANZANIA = "Tansania",
    THAILAND = "Thailand",
    TIMOR_LESTE = "Timor-Leste",
    TOGO = "Togo",
    TONGA = "Tonga",
    TRINIDAD_AND_TOBAGO = "Trinidad und Tobago",
    CHAD = "Tschad",
    CZECH_REPUBLIC = "Tschechische Republik",
    TUNISIA = "Tunesien",
    TURKEY = "Türkei",
    TURKMENISTAN = "Turkmenistan",
    TUVALU = "Tuvalu",
    UGANDA = "Uganda",
    UKRAINE = "Ukraine",
    HUNGARY = "Ungarn",
    URUGUAY = "Uruguay",
    UZBEKISTAN = "Usbekistan",
    VANUATU = "Vanuatu",
    VATICAN_CITY = "Vatikanstadt",
    VENEZUELA = "Venezuela",
    UNITED_ARAB_EMIRATES = "Vereinigte Arabische Emirate",
    UNITED_STATES = "Vereinigte Staaten",
    VIETNAM = "Vietnam",
    CENTRAL_AFRICAN_REPUBLIC = "Zentralafrikanische Republik",
    CYPRUS = "Zypern",
}
