import React, { ReactNode, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { useCommonThemeProps } from "src/shared/hooks/useCommonTheme";
import { ApplicationRoute } from "src/shared/types/route";

type SidebarType = "mobile" | "narrow" | "wide";

const HIDDEN_SIDEBAR_ROUTES: ApplicationRoute[] = [
    ApplicationRoute.LOGIN,
    ApplicationRoute.SURVEY,
    ApplicationRoute.ADDITIONAL_INFO,
    ApplicationRoute.CREATE_DEAL,
];

export const SIDEBAR_TYPE_WIDTH: Record<SidebarType, number> = {
    mobile: 64,
    narrow: 64,
    wide: 190,
};

type SidebarProviderProps = {
    children: ReactNode;
};

interface SidebarContextData {
    sidebarOpen: boolean;
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isWideSidebar: boolean;
    sidebarWidth: number;
    sidebarBoxWidth: number;
    display: boolean;
}

const initialSidebarContextData: SidebarContextData = {
    sidebarOpen: false,
    setSidebarOpen: undefined,
    isWideSidebar: false,
    sidebarWidth: 0,
    sidebarBoxWidth: 0,
    display: true,
};

export const SidebarContext = React.createContext(initialSidebarContextData);

export const SidebarProvider = ({ children }: SidebarProviderProps) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const location = useLocation();

    const { mobileScreen } = useCommonThemeProps();

    const isWideSidebar = sidebarOpen;

    const sidebarWidth = mobileScreen
        ? SIDEBAR_TYPE_WIDTH["mobile"]
        : isWideSidebar
          ? SIDEBAR_TYPE_WIDTH["wide"]
          : SIDEBAR_TYPE_WIDTH["narrow"];

    const sidebarBoxWidth = mobileScreen ? SIDEBAR_TYPE_WIDTH["mobile"] : SIDEBAR_TYPE_WIDTH["narrow"];

    return (
        <SidebarContext.Provider
            value={{
                sidebarOpen,
                setSidebarOpen,
                display: !HIDDEN_SIDEBAR_ROUTES.includes(location.pathname as ApplicationRoute),
                isWideSidebar,
                sidebarWidth,
                sidebarBoxWidth,
            }}
        >
            {children}
        </SidebarContext.Provider>
    );
};

export const useSidebarContext = () => useContext(SidebarContext);
