export type FeeFields = {
    agencyFee: number;
    estateAgentFee: number;
    purchaseContractCreationFee: number;
    realEstateTransferFee: number;
    propertyRegistrationFee: number;
};

export interface EngineRequestConfig {
    applyAgencyFeeHouseBankReduction: boolean;
}

export const buyDefaultFees: FeeFields = {
    agencyFee: 3,
    estateAgentFee: 3.6,
    purchaseContractCreationFee: 2,
    realEstateTransferFee: 3.5,
    propertyRegistrationFee: 1.1,
};

export const refinancingDefaultFees: FeeFields = {
    agencyFee: 2,
    estateAgentFee: 0,
    purchaseContractCreationFee: 0,
    realEstateTransferFee: 0,
    propertyRegistrationFee: 0,
};

export const constructionWithLandPurchaseDefaultFees: FeeFields = {
    agencyFee: 3,
    estateAgentFee: 3.6,
    purchaseContractCreationFee: 2,
    realEstateTransferFee: 3.5,
    propertyRegistrationFee: 1.1,
};

export const constructionWithoutLandPurchaseDefaultFees: FeeFields = {
    agencyFee: 3,
    estateAgentFee: 0,
    purchaseContractCreationFee: 0,
    realEstateTransferFee: 0,
    propertyRegistrationFee: 0,
};

export const defaultEstimateInterestRate = 3.6;
export const defaultInterestRate: number = 3.3;
