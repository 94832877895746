export enum EmailTemplate {
    SURVEY_SUBMIT_TIPSTER_CUSTOMER = "surveySubmitTipsterCustomer",
    DEAL_FORM_SUBMIT_BY_TIPSTER = "dealFormSubmitByTipster",
    SURVEY_SUBMIT_COMMERCIAL_OR_OTHER = "surveySubmitCommercialOrOther",
    /* Deal Stage Change E-Mails Templates */
    SURVEY_SUBMIT_APPOINTMENT_BOOKED = "surveySubmitAppointmentBooked",
    SURVEY_SUBMIT_HIGH_INTENT = "surveySubmitHighIntent",
    SURVEY_SUBMIT_HIGH_INTENT_NO_PHONE_NUMBER = "surveySubmitHighIntentNoPhoneNumber",
    AUTOMATED_EMAIL_CUSTOMER_REACHED_COLDCALL = "automatedEmailCustomerReachedColdcall",
    AUTOMATED_EMAIL_CUSTOMER_NOT_REACHED_COLDCALL = "automatedEmailCustomerNotReachedColdcall",
    AUTOMATED_EMAIL_CUSTOMER_NOT_REACHED_OTHER = "automatedEmailCustomerNotReachedOther",
    AUTOMATED_EMAIL_CUSTOMER_NOT_REACHED_IMMOSCOUT = "automatedEmailCustomerNotReachedImmoscout",
    AUTOMATED_EMAIL_CUSTOMER_NOT_REACHED_REAL_ESTATE_AGENT = "automatedEmailCustomerNotReachedRealEstateAgent",
    WAITING_FOR_OFFER = "automatedEmailNextStepsWaitingForOffer",
    CONTACT_DATA = "automatedEmailContactData",
    MAGIC_LINK = "automatedEmailMagicLink",
    NEXT_STEPS_NOT_REACHED = "nextStepsNotReached",
    DATE_PROPOSED = "automatedEmailNextStepsDateProposed",
    WAITING_FOR_SOMETHING_IN_GENERAL = "automatedEmailNextStepsWaitingForSomethingInGeneral",
    SIGHTSEEING = "automatedEmailNextStepsSightseeing",
    FOLLOW_UP = "automatedEmailNextStepsFollowUp",
    ARCHIVE_COMPETITION = "automatedEmailNextStepsArchiveCompetition",
    ARCHIVE_NOT_REACHED = "automatedEmailNextStepsArchiveNotReached",
    ARCHIVE_NO_ANSWER = "automatedEmailNextStepsArchiveNoAnswer",
    ARCHIVE_WRONG_NUMBER = "automatedEmailNextStepsArchiveWrongNumber",

    SECOND_EMAIL_WAITING_FOR_OFFER = "automatedEmailNextStepsSecondEmailWaitingForOffer",
    SECOND_EMAIL_DATE_PROPOSED = "automatedEmailNextStepsSecondEmailDateProposed",
    SECOND_EMAIL_WAITING_FOR_SOMETHING_IN_GENERAL = "nextStepsSecondSomethingInGeneral",
    SECOND_EMAIL_SIGHTSEEING = "automatedEmailNextStepsSecondEmailSightseeing",
    SECOND_EMAIL_FOLLOW_UP = "automatedEmailNextStepsSecondEmailFollowUp",

    THIRD_EMAIL = "automatedEmailNextStepsThirdEmail",
    AUTOMATED_EMAIL_OFFER_COMPARISON = "automatedEmailOfferComparison",

    AUTOMATED_EMAIL_CONSULTATION_TAB_MEETING_COMPLETION = "automatedEmailConsultationTabMeetingCompletion",
    AUTOMATED_EMAIL_MISSING_DOCUMENTS = "automatedEmailMissingDocuments",
    AUTOMATED_EMAIL_UPLOAD_DOCUMENTS = "automatedEmailUploadDocuments",

    FINLINE_SUBMISSION = "finlineSubmission",

    AUTOMATED_EMAIL_BANK_OFFER_BANK_AUSTRIA = "automatedEmailBankOfferBankAustria",
    AUTOMATED_EMAIL_BANK_OFFER_BAWAG = "automatedEmailBankOfferBawag",
    AUTOMATED_EMAIL_BANK_OFFER_ERSTE = "automatedEmailBankOfferErste",
    AUTOMATED_EMAIL_BANK_OFFER_HYPO_NOE_W = "automatedEmailBankOfferHypoNoeW",
    AUTOMATED_EMAIL_BANK_OFFER_BANK_99 = "automatedEmailBankOfferBank99",
    AUTOMATED_EMAIL_BANK_OFFER_OBERBANK = "automatedEmailBankOfferOberbank",
    AUTOMATED_EMAIL_BANK_OFFER_RAI_BAU = "automatedEmailBankOfferRaiBau",
    AUTOMATED_EMAIL_BANK_OFFER_RAI_NW = "automatedEmailBankOfferRaiNW",
    AUTOMATED_EMAIL_BANK_OFFER_BAUSPARKASSE_OST = "automatedEmailBankOfferBausparkasseOST",
    AUTOMATED_EMAIL_BANK_OFFER_START = "automatedEmailBankOfferStart",
    AUTOMATED_EMAIL_BANK_OFFER_VOLKSBANK = "automatedEmailBankOfferVolksbank",
    AUTOMATED_EMAIL_BANK_OFFER_BAUSPARKASSE_WUSTENROT = "automatedEmailBankOfferBausparkasseWustenrot",
    AUTOMATED_EMAIL_BANK_OFFER_VOLKSBANK_W = "automatedEmailBankOfferVolksbankW",
    AUTOMATED_EMAIL_BANK_OFFER_VR_NO = "automatedEmailBankOfferVRNO",
    AUTOMATED_EMAIL_BANK_OFFER_VR_O = "automatedEmailBankOfferVRO",
    AUTOMATED_EMAIL_BANK_OFFER_SPARKASSE_WN = "automatedEmailBankOfferSparkasseWN",
}

export type GetEmailTemplateDetailsData = {
    id: string;
    active: number;
    html_content: string;
    generate_plain_content: boolean;
    name: string;
    plain_content: string;
    subject: string;
    template_id: string;
    test_data: string;
    thumbnail_url: string;
    updated_at: Date;
    user_id: number;
};
