export enum DealPropertyBuildingStatus {
    BAD = "Schlecht",
    OLD = "Abgewohnt",
    GOOD = "Gut",
    VERY_GOOD = "Sehr gut",
}

export enum DealPropertyGarage {
    NONE = "Nein",
    CARPORT = "Carport",
    SINGLE = "Einzelgarage",
    DOUBLE = "Doppelgarage",
}

export enum DealPropertyConditionType {
    NORMAL = "normal",
    GOOD = "gut",
    USED = "abgewohnt",
    VACANT = "Objekt ist leerstehend --> seit ... Jahren",
    NOT_HABITABLE = "Objekt nicht bewohnbar",
}
