import { DealNextStep, ClosedLostReasonSingleValues, JaNeinValue } from "./enums";

export enum SendDealEmailType {
    CUSTOMER_REACHED = "customerReached",
    NEXT_STEPS = "nextSteps",
    NEXT_STEPS_SECOND = "nextStepsSecond",
    CONSULTATION_TAB_MEETING_COMPLETION = "consultationTabMeetingCompletion",
    DOCUMENTS_TAB_MISSING_DOCUMENTS = "documentsTabMissingDocuments",
    DOCUMENTS_TAB_UPLOAD_DOCUMENTS = "documentsTabUploadDocuments",
    FINLINE_SUBMISSION = "finlineSubmission",
    BANK_OFFER = "bankOffer",
}

export const SEND_DEAL_EMAIL_TYPE_VALUES = Object.values(SendDealEmailType);

export type CreateTaskData = {
    create: string;
    timestamp: number;
    title: string;
};

export type SendDealEmailBody = {
    type: SendDealEmailType;
    dealNextSteps?: DealNextStep;
    scheduleEmail?: boolean;
    secondEmailScheduledTimestamp?: number;
    createTaskData?: CreateTaskData;
    closedLostReasonOther?: string;
    closedLostReasonSingle?: ClosedLostReasonSingleValues;
    customerReached?: JaNeinValue;
    sendRawHtml?: boolean;
    rawHtml?: string;
    subject?: string;
    missingDocuments?: string;
    attachedDocumentIds?: string[];
};
