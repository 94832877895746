import { Country, DealPropertyBuildingStatus, DealPropertyConditionType, DealPropertyGarage } from "../models";

export const VIENNA_TIME_ZONE = "Europe/Vienna";

export const NOT_APPLICABLE = "n. a.";

export const EMPLOYMENT_COUNTRY_VALUES = [Country.AUSTRIA, Country.GERMANY, "Sonstige"];

export const PROPERTY_BUILDING_STATUS_VALUES = Object.values(DealPropertyBuildingStatus);

export const PROPERTY_GARAGE_VALUES = Object.values(DealPropertyGarage);

export const PROPERTY_CONDITION_VALUES = Object.values(DealPropertyConditionType);
