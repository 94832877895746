import { useState } from "react";

export function useVitePreloadError() {
    const [preloadError, setPreloadError] = useState(false);

    // https://vite.dev/guide/build.html#load-error-handling
    window.addEventListener("vite:preloadError", (event) => {
        event.preventDefault();
        setPreloadError(true);
    });

    return { preloadError };
}
