import { Alert, Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { nanoid } from "nanoid";
import { useContext, useEffect, useMemo } from "react";
import { AuthContext } from "src/context/AuthContext";
import { useTranslation } from "src/context/LocaleContext";
import { sentryService } from "src/service/sentry";

interface Props {
    message: string;
}

export const ToolErrorBoundaryContainer = ({ message }: Props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { profile, isMiraclCompany } = useContext(AuthContext);

    const errorCode = useMemo(() => nanoid(), []);

    useEffect(() => {
        if (!profile) {
            return;
        }

        sentryService.setContext("ToolErrorBoundaryContainer", { profile, errorCode });
        sentryService.report(message);
    }, [profile, message, errorCode]);

    return (
        <Grid container sx={{ width: "100%", height: "100%", justifyContent: "center", flex: 1 }}>
            <Grid container item sx={{ maxWidth: "1000px", padding: 4, flexDirection: "column", gap: 2 }}>
                <Typography variant="h1" textAlign={"center"}>
                    {t("errorBoundary.tool.title")}
                </Typography>
                <Grid container item flex={1} flexDirection={"column"} marginTop={"5%"} gap={3}>
                    {!!profile && isMiraclCompany && (
                        <Grid
                            container
                            item
                            sx={{
                                borderStyle: "solid",
                                borderRadius: 2,
                                padding: 2,
                                borderColor: "gray",
                                maxHeight: "400px",
                                overflowY: "auto",
                            }}
                        >
                            <Typography
                                fontSize={18}
                                color={theme.palette.error.light}
                                sx={{ display: "flex", gap: 1, alignItems: "center" }}
                            >
                                {message}
                            </Typography>
                        </Grid>
                    )}
                    <Alert
                        severity="warning"
                        sx={{
                            fontSize: 18,
                            "& .MuiAlert-icon": {
                                fontSize: 40,
                            },
                            alignItems: "center",
                        }}
                    >
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                            <Typography sx={{ fontSize: "14px" }}>
                                {t("errorBoundary.tool.errorCode")}: {errorCode}
                            </Typography>
                            <Typography sx={{ fontSize: "14px" }}>{t("errorBoundary.tool.message")}</Typography>
                        </Box>
                    </Alert>
                    {!!profile && !isMiraclCompany && (
                        <Box>
                            <Button sx={{ width: "150px", padding: "10px 20px" }} variant="contained">
                                {t("errorBoundary.tool.cta")}
                            </Button>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
