import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import { config } from "../../config";
import packageInfo from "../../../package.json";
import { AxiosError } from "axios";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";

const enabled = config.environment !== "development";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setContext = async (name: string, context: any) => {
    Sentry.setContext(name, context);
};

let sentryInitialized = false;

/**
 * Using the Sentry from "@sentry/react" path for proper React/Sentry integration
 * React router integration done for better performance monitoring
 * https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
 */
export const sentryService = {
    started: false,
    enabled,
    init: async (userId?: string) => {
        if (!enabled || sentryInitialized) {
            return;
        }

        sentryInitialized = true;

        if (userId) {
            Sentry.setUser({ id: userId });
        }

        Sentry.init({
            dsn: "https://e5c72ebebb62cc15743a858e8bc736b5@o4508680355512320.ingest.de.sentry.io/4508680374779984",
            tracesSampleRate: 0.5,
            integrations: [
                Sentry.reactRouterV6BrowserTracingIntegration({
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                }),
            ],
            environment: config.environment,
            release: "mortgaging-frontend@v" + packageInfo.version,
            normalizeDepth: 5,
        });

        console.debug("sentry init complete");
    },
    setTag: async (tag: string, value: string) => {
        if (!enabled) {
            return;
        }
        Sentry.setTag(tag, value);
    },
    report: async (err: unknown) => {
        console.error(err);

        if (!enabled) {
            return;
        }

        if (err instanceof AxiosError) {
            Sentry.setContext("axios", err.response);
        }

        if (!(err instanceof Error)) {
            err = new Error(err as string);
        }

        Sentry.captureException(err);
    },
    setContext,
};
